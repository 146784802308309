import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { PAYMENT_LEVELS } from 'utils/constants/general/paymentLevels';

export const paymentFacet = {
  id: 'paymentFacet',
  name: 'payment',
  get title() {
    return getText('Compensation');
  },
  type: 'array',
  options: Object.keys(PAYMENT_LEVELS),
  optionTitles: PAYMENT_LEVELS,
} as const satisfies SearchFacetData;
