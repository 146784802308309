import {
  ApiEnvironment,
  ApiUser,
} from 'api/userEnvironment/types/ApiUserEnvironment';
import { configValue } from 'config/appConfigUtils';
import { AdType } from 'types/Ads/AdType';
import { LocalProductNotificationType } from 'types/LocalNotification/LocalProductNotificationType';
import { NEW_YORK_CITY_COORDINATES } from 'utils/constants/geo';
import { isLocationWithinRadius } from 'utils/geo/isLocationWithinRadius';
import {
  getUserLocation,
  isNonAdmin,
  isSalarySurveyCompleted,
} from 'utils/userEnvironment';

import { isNotificationDismissed } from './localNotifications';

type AdTypeTargetingRules = {
  gradFairAdsEnabled: boolean;
  gradFairAdsNotDismissed: boolean;
  isWithin50MilesOfNewYork: boolean;
  salarySurveyAdsNotDismissed: boolean;
  currentLocaleIsEn: boolean;
  isUS: boolean;
  isSalarySurveyNotCompleted: boolean;
  isNonAdmin: boolean;
  resumeDbAdsNotDismissed: boolean;
  isNotVisibleToEmployers: boolean;
  incompleteResumeDbSetup: boolean;
};

export const adTypeTargetingConditions: Record<
  AdType,
  (keyof AdTypeTargetingRules)[]
> = {
  [AdType.GRAD_FAIRS]: [
    'gradFairAdsEnabled',
    'gradFairAdsNotDismissed',
    'isWithin50MilesOfNewYork',
    'isNonAdmin',
  ],
  [AdType.SALARY_SURVEY]: [
    'salarySurveyAdsNotDismissed',
    'currentLocaleIsEn',
    'isUS',
    'isSalarySurveyNotCompleted',
    'isNonAdmin',
  ],
  [AdType.RESUME_DB]: [
    'resumeDbAdsNotDismissed',
    'currentLocaleIsEn',
    'isUS',
    'isNonAdmin',
    'incompleteResumeDbSetup',
  ],
};

export function computeAdTypeTargetingRules({
  user,
  environment,
}: {
  user: ApiUser | null;
  environment: ApiEnvironment;
}): AdTypeTargetingRules {
  const userLocation = getUserLocation(user, environment);

  return {
    gradFairAdsEnabled: Boolean(configValue('idealist', 'fairs', 'adsEnabled')),
    gradFairAdsNotDismissed: !isNotificationDismissed(
      LocalProductNotificationType.GRAD_FAIRS_BANNER,
    ),
    isWithin50MilesOfNewYork: isLocationWithinRadius(
      userLocation,
      NEW_YORK_CITY_COORDINATES,
      50,
    ),
    salarySurveyAdsNotDismissed: !isNotificationDismissed(
      LocalProductNotificationType.SALARY_SURVEY_CTA,
    ),
    currentLocaleIsEn: CURRENT_LOCALE() === 'en',
    isUS: environment.isUS,
    isSalarySurveyNotCompleted: !isSalarySurveyCompleted(user),
    isNonAdmin: isNonAdmin(user),
    resumeDbAdsNotDismissed: !isNotificationDismissed(
      LocalProductNotificationType.UPLOAD_RESUME,
    ),
    isNotVisibleToEmployers: user?.isRecruitable === false,
    incompleteResumeDbSetup:
      user !== null &&
      (user.isRecruitable === null || (user.isRecruitable && !user.resumeId)),
  };
}
