export const SEARCH_RADIUSES: Record<
  UserLocale,
  Array<{ id: string; name: string }>
> = {
  en: [
    {
      id: '',
      name: 'Default Radius',
    },
    {
      id: '8000',
      name: '5 miles',
    },
    {
      id: '16000',
      name: '10 miles',
    },
    {
      id: '24000',
      name: '15 miles',
    },
    {
      id: '40000',
      name: '25 miles',
    },
    {
      id: '100000',
      name: '60 miles',
    },
    {
      id: '160000',
      name: '100 miles',
    },
    {
      id: 'state',
      name: 'Entire State',
    },
    {
      id: 'country',
      name: 'Entire Country',
    },
  ],
  es: [
    {
      id: '',
      name: 'Radio por Defecto',
    },
    {
      id: '5000',
      name: `5 Km`,
    },
    {
      id: '10000',
      name: `10 Km`,
    },
    {
      id: '15000',
      name: `15 Km`,
    },
    {
      id: '25000',
      name: `25 Km`,
    },
    {
      id: '60000',
      name: `60 Km`,
    },
    {
      id: '100000',
      name: `100 Km`,
    },
    {
      id: 'state',
      name: 'Todo el Estado',
    },
    {
      id: 'country',
      name: 'Todo el País',
    },
  ],
  pt: [
    {
      id: '',
      name: 'Automático',
    },
    {
      id: '5000',
      name: `5 Km`,
    },
    {
      id: '10000',
      name: `10 Km`,
    },
    {
      id: '15000',
      name: `15 Km`,
    },
    {
      id: '25000',
      name: `25 Km`,
    },
    {
      id: '60000',
      name: `60 Km`,
    },
    {
      id: '100000',
      name: `100 Km`,
    },
    {
      id: 'state',
      name: 'Todo o Estado',
    },
    {
      id: 'country',
      name: 'País Inteiro',
    },
  ],
};

export const SEARCH_RADIUS_RADIO_OR_SELECT_OPTIONS: () => Record<
  UserLocale,
  Array<{ label: string; value: string }>
> = () =>
  (Object.keys(SEARCH_RADIUSES) as UserLocale[]).reduce(
    (acc, locale: UserLocale) => ({
      ...acc,
      [locale]: SEARCH_RADIUSES[locale].map((radius) => ({
        value: radius.id,
        label: radius.name,
      })),
    }),
    {} as Record<UserLocale, Array<{ label: string; value: string }>>,
  );
