import { TextBadge } from 'components/Badge/TextBadge';
import { SearchFilterAccordionItem } from 'modules/search/components/Filters/AccordionItem/SearchFilterAccordionItem';
import { SearchFilterDropdown } from 'modules/search/components/Filters/Dropdown/SearchFilterDropdown';
import { SearchFilterCheckboxFacetOptions } from 'modules/search/components/Filters/FacetOptions/SearchFilterCheckboxFacetOptions';
import { useSearchFacetSelectedValues } from 'modules/search/hooks/filters/useSearchFacetSelectedValues';
import { SearchFilterVariant } from 'modules/search/types/SearchFilterVariant';
import { JOB_FUNCTIONS_CHECKBOX_OPTIONS } from 'utils/constants/general/jobFunctions';

type Props = {
  variant?: SearchFilterVariant;
};
export function JobKeywordsFilter({ variant = 'dropdown' }: Props) {
  // @ts-expect-error Fix type
  const selectedFilterValues = useSearchFacetSelectedValues('functions');
  const FilterVariant =
    variant === 'accordion' ? SearchFilterAccordionItem : SearchFilterDropdown;

  return (
    <FilterVariant
      facetType="functions"
      icon="alternate-shapes"
      label={
        <>
          {getText('Job Function')}{' '}
          <TextBadge content={selectedFilterValues.length} />
        </>
      }
    >
      <SearchFilterCheckboxFacetOptions
        facetType="functions"
        initialValue={selectedFilterValues}
        options={JOB_FUNCTIONS_CHECKBOX_OPTIONS()}
        searchable
      />
    </FilterVariant>
  );
}
