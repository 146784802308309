import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { EVENT_LOCATION_TYPE } from 'utils/constants/general/eventLocationType';
import { LOCATION_TYPE } from 'utils/constants/general/locationType';

export const locationTypeFacet = {
  id: 'locationTypeFacet',
  name: 'locationType',
  get title() {
    return getText('Location');
  },
  type: 'array',
  options: Object.keys(LOCATION_TYPE),
  optionTitles: LOCATION_TYPE,
} as const satisfies SearchFacetData;

export const eventLocationTypeFacet = {
  id: 'locationTypeFacet',
  name: 'locationType',
  get title() {
    return getText('Location');
  },
  type: 'array',
  options: Object.keys(LOCATION_TYPE),
  optionTitles: EVENT_LOCATION_TYPE,
} as const satisfies SearchFacetData;
