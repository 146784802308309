import { trackEvent } from 'utils/analytics/track/trackEvent';

type TrackDisplayToastRequiredParams = {
  type: string;
  current_page_url: string;
};

export function trackDisplayToast<T extends TrackDisplayToastRequiredParams>(
  params: T,
): void {
  trackEvent('Displayed: Action Toast', params);
}
