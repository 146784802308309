import { useMemo } from 'react';

import { useAbExperimentBucket } from 'hooks/abExperiment/useAbExperimentBucket';
import { SearchFilters } from 'modules/search/components/Filters/SearchFilters';

import { EducationFilter } from './filters/EducationFilter';
import { EmploymentTypeFilter } from './filters/EmploymentTypeFilter';
import { JobFamilyFilter } from './filters/JobFamilyFilter';
import { JobKeywordsFilter } from './filters/JobKeywordsFilter';
import { LocaleFilter } from './filters/LocaleFilter';
import { LocationTypeFilter } from './filters/LocationTypeFilter';
import { OrgAreasOfFocusFilter } from './filters/OrgAreasOfFocusFilter';
import { OrgTypeFilter } from './filters/OrgTypeFilter';
import { ProfessionalLevelFilter } from './filters/ProfessionalLevelFilter';
import { RadiusFilter } from './filters/RadiusFilter';
import { RecencyFilter } from './filters/RecencyFilter';

export function SearchJobsPageFilters() {
  const [bucketValue] = useAbExperimentBucket(
    'SPLIT_SCREEN_SEARCH_INACTIVE_2024_12',
  );

  const filtersByLocale = useMemo(
    () => ({
      en: [
        <JobFamilyFilter />,
        <LocationTypeFilter />,
        <EmploymentTypeFilter />,
        <ProfessionalLevelFilter />,
        <JobKeywordsFilter />,
        <RecencyFilter />,
        <EducationFilter />,
        <OrgAreasOfFocusFilter />,
        <LocaleFilter />,
        <RadiusFilter />,
        <OrgTypeFilter />,
      ],
      es: [
        <JobFamilyFilter />,
        <LocationTypeFilter />,
        <JobKeywordsFilter />,
        <LocaleFilter />,
        <OrgAreasOfFocusFilter />,
        <RecencyFilter />,
        <EducationFilter />,
        <ProfessionalLevelFilter />,
        <EmploymentTypeFilter />,
        <OrgTypeFilter />,
        <RadiusFilter />,
      ],
      pt: [
        <JobFamilyFilter />,
        <LocationTypeFilter />,
        <JobKeywordsFilter />,
        <OrgAreasOfFocusFilter />,
        <LocaleFilter />,
        <ProfessionalLevelFilter />,
        <EducationFilter />,
        <EmploymentTypeFilter />,
        <RecencyFilter />,
        <OrgTypeFilter />,
        <RadiusFilter />,
      ],
    }),
    [],
  );

  return bucketValue === 'ENABLED' ? (
    <SearchFilters>{filtersByLocale[CURRENT_LOCALE()]}</SearchFilters>
  ) : null;
}
