import {
  Children,
  PropsWithChildren,
  ReactNode,
  cloneElement,
  isValidElement,
} from 'react';

import { AccordionItem } from 'components/Accordion/Item/AccordionItem';
import { DropdownChevron } from 'components/Dropdown/Chevron/DropdownChevron';
import { Icon } from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconName';
import { SearchJobsFilterKeys } from 'modules/search/constants/filters/searchJobsFilters';
import { SearchFilterVariant } from 'modules/search/types/SearchFilterVariant';

import {
  SearchFilterAccordionSummary,
  SearchFilterAccordionSummaryLabel,
} from './SearchFilterAccordionItem.styled';

type Props = {
  facetType: SearchJobsFilterKeys;
  label: ReactNode;
  icon?: IconName;
};

export function SearchFilterAccordionItem({
  children,
  facetType,
  icon,
  label,
}: PropsWithChildren<Props>) {
  return (
    <AccordionItem
      name="search-filter"
      header={
        <SearchFilterAccordionSummary>
          <SearchFilterAccordionSummaryLabel>
            {icon ? <Icon size={18} name={icon} /> : undefined}
            <span>{label}</span>
          </SearchFilterAccordionSummaryLabel>
          <DropdownChevron size={16} />
        </SearchFilterAccordionSummary>
      }
    >
      <div data-facet-type={facetType}>
        {isValidElement<{ variant: SearchFilterVariant }>(children)
          ? Children.only(cloneElement(children, { variant: 'accordion' }))
          : null}
      </div>
    </AccordionItem>
  );
}
