import { HttpError } from 'utils/HttpError';
import { addFlashMessageForNextPageLoad } from 'utils/cookies';

import { parseJsonResponse } from './parseJsonResponse';

export function handleErrors(
  status: number,
  url: string,
  resolve: () => void,
  reject: () => void,
  options: { skipRedirects?: boolean; retriesRemaining?: number } = {},
) {
  if (status > 199 && status < 300) {
    resolve();
    return;
  }

  if (options.skipRedirects) {
    reject();
    return;
  }

  let loginPath = '/login';
  let homePath = `/${CURRENT_LOCALE()}/`;

  if (window.location.pathname.startsWith('/fair')) {
    homePath = '/fair';
    loginPath = '/fair/login';
  }

  if (/\/[^/]/.test(url)) {
    // don't redirect on 401/403 for algolia requests
    if (status === 401) {
      addFlashMessageForNextPageLoad('LOGIN_REQUIRED');
      window.location.href = loginPath;
      return;
    }

    if (status === 403) {
      addFlashMessageForNextPageLoad('FORBIDDEN');
      window.location.href = homePath;
      return;
    }

    const { retriesRemaining } = options;
    if (
      (status === 408 ||
        /* Request Timeout */
        status >= 500) &&
      retriesRemaining
    ) {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getJsonInternal(url, resolve, reject, retriesRemaining - 1);
      }, 1000);
      return;
    }

    reject();
    return;
  }

  reject();
}

export function getJsonInternal<TData>(
  url: string,
  resolve: (data: TData) => void,
  reject: (error: Error) => void,
  retriesRemaining: number,
) {
  const xhr = new XMLHttpRequest();
  xhr.addEventListener('load', () => {
    handleErrors(
      xhr.status,
      url,
      () => resolve(parseJsonResponse(xhr, reject)),
      () => reject(new HttpError('GET', url, xhr)),
      {
        retriesRemaining,
      },
    );
  });
  xhr.open('GET', url, true);
  xhr.setRequestHeader('Accept', 'application/json');
  xhr.send();
}
