import { RouteObject } from 'react-router';

import { apiFetchInvite } from 'api/invite/apiFetchInvite';
import { apiFetchInvoice } from 'api/invoice/apiFetchInvoice';
import { RequireAuthentication } from 'containers/RequireAuthentication';
import { Layout } from 'layouts/Layout/Layout';
import { UserInvitePageLoadable } from 'modules/auth/pages/userInvite/UserInvitePageLoadable';
import { authRouteObjects } from 'modules/auth/routing/auth.route-objects';
import { cmsRouteObjects } from 'modules/cms/routing/cms.route-objects';
import { importedListingRouteObjects } from 'modules/listing/routing/imported/imported-listing.route-objects';
import { listingRouteObjects } from 'modules/listing/routing/listing.route-objects';
import { ViewInvoicePageLoadable } from 'modules/orgDashboard/pages/invoices/publicView/ViewInvoicePageLoadable';
import { RateRecommendedCandidateLoadable } from 'modules/orgDashboard/pages/recommendedCandidates/rate/RateRecommendedCandidateLoadable';
import { orgDashboardRouteObjects } from 'modules/orgDashboard/routing/orgDashboard.route-objects';
import { salariesRouteObjects } from 'modules/salaries/routing/salaries.route-objects';
import { searchRouteObjects } from 'modules/search/routing/search.route-objects';
import { CreateOrgPageContainerLoadable } from 'modules/userDashboard/pages/CreateOrg/CreateOrgPageContainerLoadable';
import { getUserDashboardRouteObjects } from 'modules/userDashboard/routing/userDashboard.route-objects';
import { userProfileRouteObjects } from 'modules/userProfile/routing/userProfile.route-objects';
import { InvalidLinkLoadable } from 'pages/InvalidLink/InvalidLinkLoadable';
import { UnsubscribePageLoadable } from 'pages/Unsubscribe/UnsubscribeLoadable';
import { loadableLoader } from 'routing/utils/loadableLoader';

import { AppRouteLoaderArgs } from './classes/types/AppRouteLoaderArgs';
import { SIGN_UP_TO_POST_URL_FOR_LOCALE } from './route-constants';
import {
  emailRateRecommendedCandidateRoute,
  userInviteRoute,
  viewDonationReceiptRoute,
  viewInvoiceRoute,
} from './routes';
import { renderLoader } from './utils/renderLoader/renderLoader';

export function getRouteObjects(): RouteObject[] {
  return [
    {
      Component: Layout,
      children: [
        {
          path: '/unsubscribe/:preferenceName/:userId/:timestamp',
          element: <UnsubscribePageLoadable />,
        },
        {
          path: '/unsubscribe/org/:orgId/:preferenceName/:userId/:timestamp',
          element: <UnsubscribePageLoadable />,
        },
        {
          path: '/invalid-or-expired-link',
          loader: loadableLoader(InvalidLinkLoadable),
          Component: InvalidLinkLoadable,
        },
        {
          path: viewInvoiceRoute.fullPath,
          ...renderLoader({
            loader: async ({
              params,
            }: AppRouteLoaderArgs<typeof viewInvoiceRoute>) => {
              const [data] = await Promise.all([
                apiFetchInvoice(params.invoiceId as string),
                ViewInvoicePageLoadable.load(),
              ]);

              return data;
            },
            render: (data) => (
              <ViewInvoicePageLoadable org={data.org} invoice={data.invoice} />
            ),
          }),
        },
        {
          path: viewDonationReceiptRoute.fullPath,
          ...renderLoader({
            loader: async ({
              params,
            }: AppRouteLoaderArgs<typeof viewInvoiceRoute>) => {
              const [data] = await Promise.all([
                apiFetchInvoice(params.invoiceId as string),
                ViewInvoicePageLoadable.load(),
              ]);

              return data;
            },
            render: (data) => (
              <ViewInvoicePageLoadable
                org={data.org}
                invoice={data.invoice}
                isDonation
              />
            ),
          }),
        },
        ...authRouteObjects,
        ...listingRouteObjects,
        ...searchRouteObjects,
        ...cmsRouteObjects,
        ...salariesRouteObjects,
        ...getUserDashboardRouteObjects(),
        ...orgDashboardRouteObjects,
        ...userProfileRouteObjects,
      ],
    },

    /*
     * Routes below this line need to be converted to the new routing paradigm
     *
     * To accomplish that:
     * 1. Update the loadable to have a fallback `<LoadingSection />` instead of
     *    `<LoadableLoadingPage />`, because the layout will already have the header
     * 2. Remove the layout from the page, the layout is now placed on the router.
     * 3. Move it to the corresponding `element: <_TheLayout/>` wrapper. If the route
     *    layout requires data from a user-only module, use a loadable layout to
     *    avoid bloating the main bundle
     * 4. Add `handle` properties as necessary to modify the layout
     * ---------------------------------------------------------------------------
     */

    ...importedListingRouteObjects,
    {
      path: userInviteRoute.fullPath,
      ...renderLoader({
        loader: async ({
          params,
        }: AppRouteLoaderArgs<typeof userInviteRoute>) => {
          const [invite] = await Promise.all([
            apiFetchInvite(params.inviteId as string),
            UserInvitePageLoadable.load(),
          ]);

          return invite;
        },
        render: (invite) => <UserInvitePageLoadable invite={invite} />,
      }),
    },
    {
      path: '/organizations/create',
      element: (
        <RequireAuthentication
          loginPathname={SIGN_UP_TO_POST_URL_FOR_LOCALE[CURRENT_LOCALE()]}
        >
          <CreateOrgPageContainerLoadable />
        </RequireAuthentication>
      ),
    },
    {
      path: emailRateRecommendedCandidateRoute.fullPath,
      element: (
        <RequireAuthentication>
          <RateRecommendedCandidateLoadable />
        </RequireAuthentication>
      ),
    },
    ...['/cms/*', '/staff/*'].map(
      (staffPath) =>
        ({
          path: staffPath,
          Component: () => {
            // Reload the page, going from main to the staff app
            window.location.reload();
            return null;
          },
        }) satisfies RouteObject,
    ),
  ] satisfies RouteObject[];
}
