export enum RecencyPeriod {
  DEFAULT = '',
  PAST_24_HOURS = 'PAST_24_HOURS',
  PAST_WEEK = 'PAST_WEEK',
  PAST_MONTH = 'PAST_MONTH',
}

export const RECENCY_PERIODS = Object.defineProperties(
  {},
  {
    '': { get: () => getText('Anytime'), enumerable: true },
    PAST_24_HOURS: { get: () => getText('Past 24 hours'), enumerable: true },
    PAST_WEEK: { get: () => getText('Past week'), enumerable: true },
    PAST_MONTH: { get: () => getText('Past month'), enumerable: true },
  },
) as Record<RecencyPeriod, string>;

export const RECENCY_PERIOD_RADIO_OR_SELECT_OPTIONS = () =>
  Object.entries(RECENCY_PERIODS).map(([key, value]) => ({
    value: key,
    label: value,
  }));
