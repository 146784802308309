export enum PaymentLevel {
  HOURLY = 'HOURLY',
  SALARY = 'SALARY',
  STIPEND = 'STIPEND',
}

export const PAYMENT_LEVELS = Object.defineProperties(
  {},
  {
    HOURLY: { get: () => getText('Hourly'), enumerable: true },
    SALARY: { get: () => getText('Salary'), enumerable: true },
    STIPEND: { get: () => getText('Stipend'), enumerable: true },
  },
) as Record<PaymentLevel, string>;
