import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { RECENCY_PERIODS } from 'utils/constants/general/recency';

export const recencyFacet = {
  id: 'recencyFacet',
  name: 'recency',
  get title() {
    return getText('Date Posted');
  },
  type: 'string',
  options: Object.keys(RECENCY_PERIODS),
  optionTitles: RECENCY_PERIODS,
} as const satisfies SearchFacetData;
