import { useCallback, useEffect, useState } from 'react';

import {
  ActionToastIconWrapper,
  DismissButton,
  ToastActions,
  ToastActionsAndContent,
  ToastBody,
  ToastContent,
  ToastHeadline,
  ToastLayout,
  ToastList,
  ToastWrapper,
  animationDurationMs,
} from './ActionToast.styled';
import { ActionButton } from './ActionToastButton';
import { ActionToastFeedbackButton } from './ActionToastFeedbackButton';
import {
  ToastDismissedEventDetail,
  ToastOptions,
  useToastStore,
} from './useActionToast';
import { useOnSwipeDown } from './useOnSwipeDown';

type ActionToastProps = ToastOptions & {
  onDismiss: () => void;
};

export function ActionToast({ onDismiss, ...toast }: ActionToastProps) {
  const isMobile = 'ontouchstart' in window;
  const layout = toast.layout || 'compact';

  const [isDismissing, setIsDismissing] = useState(false);
  const handleDismiss = useCallback(async () => {
    setIsDismissing(true);
    await new Promise((resolve) => {
      window.setTimeout(resolve, animationDurationMs);
    });
    onDismiss();
  }, [onDismiss]);

  useEffect(() => {
    const handleToastDismissed = (event: Event) => {
      const customEvent = event as CustomEvent<ToastDismissedEventDetail>;
      if (customEvent.detail.id !== toast.id) return;
      handleDismiss();
    };
    window.addEventListener('idealist:toast-dismissed', handleToastDismissed);
    return () => {
      window.removeEventListener(
        'idealist:toast-dismissed',
        handleToastDismissed,
      );
    };
  }, [handleDismiss, toast.id]);

  const { handlers } = useOnSwipeDown({ onSwipeDown: handleDismiss });
  const onSwipeHandlers = isMobile ? handlers : {};

  return (
    <ToastWrapper
      data-qa-id={`action-toast-${toast.id || ''}`}
      $variant={toast.variant}
      $isDismissing={isDismissing}
      $isMobile={isMobile}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...onSwipeHandlers}
    >
      <ToastLayout>
        {toast.icon && (
          <ActionToastIconWrapper>{toast.icon}</ActionToastIconWrapper>
        )}
        <ToastActionsAndContent $layout={layout}>
          <ToastContent>
            <ToastHeadline data-qa-id="toast-headline">
              {toast.headline}
            </ToastHeadline>
            {toast.body && (
              <ToastBody data-qa-id="toast-body">{toast.body}</ToastBody>
            )}
          </ToastContent>

          <ToastActions data-qa-id="toast-actions">
            {toast.actions?.map((action) =>
              action.type === 'button' ? (
                <ActionButton
                  key={action.label}
                  data-qa-id={`toast-action-${action.label.toLowerCase().replace(/\s+/g, '-')}`}
                  label={action.label}
                  onClick={action.onClick}
                  type={action.type}
                />
              ) : (
                <ActionToastFeedbackButton
                  key={action.id}
                  data-qa-id={`toast-feedback-${action.id}`}
                  onFeedback={action.onFeedback}
                  positiveLabel={action.positiveLabel}
                  negativeLabel={action.negativeLabel}
                />
              ),
            )}
          </ToastActions>
        </ToastActionsAndContent>
      </ToastLayout>
      {toast.dismissible && (
        <DismissButton
          data-qa-id="toast-dismiss-button"
          onClick={handleDismiss}
        />
      )}
    </ToastWrapper>
  );
}

export function ActionToastProvider() {
  const { toasts, dismissToast } = useToastStore();

  return (
    <ToastList data-qa-id="toast-list">
      {toasts.map((toast) => (
        <ActionToast
          key={toast.id}
          // TODO(@paprikka): drop the spread once we have a better idea of the
          // shape
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...toast}
          onDismiss={() => dismissToast(toast.id || '')}
        />
      ))}
    </ToastList>
  );
}
