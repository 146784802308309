import { RouteObject } from 'react-router';

import {
  ApiEventId,
  ApiInternshipId,
  ApiJobId,
  ApiOrgId,
  ApiVolopId,
} from 'api/types/ApiTypedId';
import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardApiFetchEvent } from 'modules/orgDashboard/api/listing/orgDashboardApiFetchEvent';
import { orgDashboardApiFetchJob } from 'modules/orgDashboard/api/listing/orgDashboardApiFetchJob';
import { orgDashboardApiFetchVolop } from 'modules/orgDashboard/api/listing/orgDashboardApiFetchVolop';
import { orgDashboardApiFetchInternship } from 'modules/orgDashboard/api/listing/orgDashboardApiInternship';
import { OrgDashboardPreviewEventPageLoadable } from 'modules/orgDashboard/pages/events/preview/OrgDashboardPreviewEventPageLoadable';
import { OrgDashboardViewEventPageLoadable } from 'modules/orgDashboard/pages/events/view/OrgDashboardViewEventPageLoadable';
import { OrgDashboardPreviewInternshipPageLoadable } from 'modules/orgDashboard/pages/internships/preview/OrgDashboardPreviewInternshipPageLoadable';
import { OrgDashboardViewInternshipPageContainerLoadable } from 'modules/orgDashboard/pages/internships/view/OrgDashboardViewInternshipPageContainerLoadable';
import { OrgDashboardPreviewJobPageLoadable } from 'modules/orgDashboard/pages/jobs/preview/OrgDashboardPreviewJobPageLoadable';
import { OrgDashboardViewJobPageContainerLoadable } from 'modules/orgDashboard/pages/jobs/view/OrgDashboardViewJobPageContainerLoadable';
import { OrgDashboardViewListingApplicationPageContainerLoadable } from 'modules/orgDashboard/pages/listings/viewApplication/OrgDashboardViewListingApplicationPageContainerLoadable';
import { OrgDashboardPreviewOrgPageContainerLoadable } from 'modules/orgDashboard/pages/orgs/preview/OrgDashboardPreviewOrgPageContainerLoadable';
import { OrgDashboardRecommendedCandidateDetailPageLoadable } from 'modules/orgDashboard/pages/recommendedCandidates/view/OrgDashboardRecommendedCandidateDetailPageLoadable';
import { OrgDashboardPreviewVolopPageLoadable } from 'modules/orgDashboard/pages/volops/preview/OrgDashboardPreviewVolopPageLoadable';
import { OrgDashboardViewVolopPageLoadable } from 'modules/orgDashboard/pages/volops/view/OrgDashboardViewVolopPageLoadable';
import { AppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import { renderLoader } from 'routing/utils/renderLoader/renderLoader';

import {
  orgDashboardPreviewEventRoute,
  orgDashboardPreviewInternshipRoute,
  orgDashboardPreviewJobRoute,
  orgDashboardPreviewOrgRoute,
  orgDashboardPreviewVolopRoute,
  orgDashboardViewApplicationRoute,
  orgDashboardViewEventRoute,
  orgDashboardViewInternshipRoute,
  orgDashboardViewJobRecommendedCandidatesRoute,
  orgDashboardViewJobRoute,
  orgDashboardViewVolopRoute,
} from './orgDashboard.routes';

export const noSubLayoutOrgDashboardRouteObjects = [
  {
    path: orgDashboardPreviewOrgRoute.relativePath,
    Component: OrgDashboardPreviewOrgPageContainerLoadable,
  },
  {
    path: orgDashboardViewJobRoute.relativePath,
    shouldRevalidate: () => false,
    Component: OrgDashboardViewJobPageContainerLoadable,
  },
  {
    path: orgDashboardViewInternshipRoute.relativePath,
    Component: OrgDashboardViewInternshipPageContainerLoadable,
  },
  {
    path: orgDashboardViewVolopRoute.relativePath,
    Component: OrgDashboardViewVolopPageLoadable,
  },
  {
    path: orgDashboardViewEventRoute.relativePath,
    Component: OrgDashboardViewEventPageLoadable,
  },
  {
    path: orgDashboardViewApplicationRoute.relativePath,
    Component: OrgDashboardViewListingApplicationPageContainerLoadable,
  },
  {
    path: orgDashboardViewJobRecommendedCandidatesRoute.relativePath,
    handle: {
      fallbackUrlByLocaleToCurrent: false,
    } satisfies LayoutHandleProps,
    Component: OrgDashboardRecommendedCandidateDetailPageLoadable,
  },
  // Preview listing routes
  {
    path: orgDashboardPreviewJobRoute.relativePath,
    handle: {
      darkBackground: false,
    },
    ...renderLoader({
      clientOnly: true,
      loader: ({
        params,
      }: AppRouteLoaderArgs<typeof orgDashboardPreviewJobRoute>) => {
        const { orgId, jobId } = params;
        return orgDashboardApiFetchJob(orgId as ApiOrgId, jobId as ApiJobId);
      },
      render: (job) => <OrgDashboardPreviewJobPageLoadable job={job} />,
    }),
  },
  {
    path: orgDashboardPreviewInternshipRoute.relativePath,
    handle: {
      darkBackground: false,
    },
    ...renderLoader({
      clientOnly: true,
      loader: ({
        params,
      }: AppRouteLoaderArgs<typeof orgDashboardPreviewInternshipRoute>) => {
        const { internshipId, orgId } = params;

        return orgDashboardApiFetchInternship(
          orgId as ApiOrgId,
          internshipId as ApiInternshipId,
        );
      },
      render: (internship) => (
        <OrgDashboardPreviewInternshipPageLoadable internship={internship} />
      ),
    }),
  },
  {
    path: orgDashboardPreviewVolopRoute.relativePath,
    handle: {
      darkBackground: false,
    },
    ...renderLoader({
      loader: ({
        params,
      }: AppRouteLoaderArgs<typeof orgDashboardPreviewVolopRoute>) => {
        const { orgId, volopId } = params;

        return orgDashboardApiFetchVolop(
          orgId as ApiOrgId,
          volopId as ApiVolopId,
        );
      },
      render: (volop) => <OrgDashboardPreviewVolopPageLoadable volop={volop} />,
    }),
  },
  {
    path: orgDashboardPreviewEventRoute.relativePath,
    handle: {
      darkBackground: false,
    },
    ...renderLoader({
      clientOnly: true,
      loader: ({
        params,
      }: AppRouteLoaderArgs<typeof orgDashboardPreviewEventRoute>) => {
        const { orgId, eventId } = params;

        return orgDashboardApiFetchEvent(
          orgId as ApiOrgId,
          eventId as ApiEventId,
        );
      },
      render: (event) => <OrgDashboardPreviewEventPageLoadable event={event} />,
    }),
  },
] satisfies RouteObject[];
