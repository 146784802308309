export enum JobFunction {
  ACCOUNTING_FINANCE = 'ACCOUNTING_FINANCE',
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  ADVOCACY = 'ADVOCACY',
  ARTS_PHOTOGRAPHY = 'ARTS_PHOTOGRAPHY',
  BUSINESS_DEVELOPMENT = 'BUSINESS_DEVELOPMENT',
  CASE_SOCIAL_WORK = 'CASE_SOCIAL_WORK',
  CITIZENSHIP = 'CITIZENSHIP',
  COMMUNICATIONS = 'COMMUNICATIONS',
  COMMUNITY_BELONGING = 'COMMUNITY_BELONGING',
  COMMUNITY_ENGAGEMENT_OUTREACH = 'COMMUNITY_ENGAGEMENT_OUTREACH',
  COMMUNITY_ORGANIZING = 'COMMUNITY_ORGANIZING',
  CONSERVATION = 'CONSERVATION',
  CORPORATE_PHILANTHROPY = 'CORPORATE_PHILANTHROPY',
  CORPORATE_RESPONSIBILITY = 'CORPORATE_RESPONSIBILITY',
  COUNSELING = 'COUNSELING',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  DATABASE_ADMINISTRATION = 'DATABASE_ADMINISTRATION',
  DATA_EVALUATION_ANALYSIS = 'DATA_EVALUATION_ANALYSIS',
  DEVELOPMENT_FUNDRAISING = 'DEVELOPMENT_FUNDRAISING',
  DIRECT_SERVICES = 'DIRECT_SERVICES',
  DIVERSITY_EQUITY_INCLUSION = 'DIVERSITY_EQUITY_INCLUSION',
  EMPLOYEE_ENGAGEMENT = 'EMPLOYEE_ENGAGEMENT',
  EQUIPMENT_FACILITIES = 'EQUIPMENT_FACILITIES',
  EVENTS = 'EVENTS',
  PUBLIC_RELATIONS = 'PUBLIC_RELATIONS',
  GRANTS_ADMINISTRATION = 'GRANTS_ADMINISTRATION',
  GRAPHIC_DESIGN = 'GRAPHIC_DESIGN',
  HEALTHCARE_PROVIDER_PRACTITIONER = 'HEALTHCARE_PROVIDER_PRACTITIONER',
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
  INVESTIGATIONS = 'INVESTIGATIONS',
  LEGAL = 'LEGAL',
  MARKETING = 'MARKETING',
  OPERATIONS = 'OPERATIONS',
  PARTNERSHIPS = 'PARTNERSHIPS',
  PROGRAM_MANAGEMENT = 'PROGRAM_MANAGEMENT',
  RESEARCH = 'RESEARCH',
  SAFETY_RESOURCE_PROTECTION = 'SAFETY_RESOURCE_PROTECTION',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  STRATEGY_PLANNING = 'STRATEGY_PLANNING',
  SUPPLIES_PROCUREMENT_DISTRIBUTION = 'SUPPLIES_PROCUREMENT_DISTRIBUTION',
  SUSTAINABILITY = 'SUSTAINABILITY',
  TEACHING_INSTRUCTION = 'TEACHING_INSTRUCTION',
  TECHNOLOGY_IT = 'TECHNOLOGY_IT',
  WRITING_EDITORIAL = 'WRITING_EDITORIAL',
}

export const JOB_FUNCTIONS = Object.defineProperties(
  {},
  {
    [JobFunction.ACCOUNTING_FINANCE]: {
      get: () => getText('Accounting & Finance'),
      enumerable: true,
    },
    [JobFunction.ADMINISTRATIVE]: {
      get: () => getText('Administrative'),
      enumerable: true,
    },
    [JobFunction.ADVOCACY]: {
      get: () => getText('Advocacy'),
      enumerable: true,
    },
    [JobFunction.ARTS_PHOTOGRAPHY]: {
      get: () => getText('Arts & Photography'),
      enumerable: true,
    },
    [JobFunction.BUSINESS_DEVELOPMENT]: {
      get: () => getText('Business Development'),
      enumerable: true,
    },
    [JobFunction.CASE_SOCIAL_WORK]: {
      get: () => getText('Case/Social Work'),
      enumerable: true,
    },
    [JobFunction.CITIZENSHIP]: {
      get: () => getText('Citizenship'),
      enumerable: true,
    },
    [JobFunction.COMMUNICATIONS]: {
      get: () => getText('Communications'),
      enumerable: true,
    },
    [JobFunction.COMMUNITY_BELONGING]: {
      get: () => getText('Community Belonging'),
      enumerable: true,
    },
    [JobFunction.COMMUNITY_ENGAGEMENT_OUTREACH]: {
      get: () => getText('Community Engagement/Outreach'),
      enumerable: true,
    },
    [JobFunction.COMMUNITY_ORGANIZING]: {
      get: () => getText('Community Organizing'),
      enumerable: true,
    },
    [JobFunction.CONSERVATION]: {
      get: () => getText('Conservation'),
      enumerable: true,
    },
    [JobFunction.CORPORATE_PHILANTHROPY]: {
      get: () => getText('Corporate Philanthropy'),
      enumerable: true,
    },
    [JobFunction.CORPORATE_RESPONSIBILITY]: {
      get: () => getText('Corporate Responsibility'),
      enumerable: true,
    },
    [JobFunction.COUNSELING]: {
      get: () => getText('Counseling'),
      enumerable: true,
    },
    [JobFunction.CUSTOMER_SERVICE]: {
      get: () => getText('Customer Service'),
      enumerable: true,
    },
    [JobFunction.DATABASE_ADMINISTRATION]: {
      get: () => getText('Database Administration'),
      enumerable: true,
    },
    [JobFunction.DATA_EVALUATION_ANALYSIS]: {
      get: () => getText('Data Evaluation/Analysis'),
      enumerable: true,
    },
    [JobFunction.DEVELOPMENT_FUNDRAISING]: {
      get: () => getText('Development/Fundraising'),
      enumerable: true,
    },
    [JobFunction.DIRECT_SERVICES]: {
      get: () => getText('Direct Services'),
      enumerable: true,
    },
    [JobFunction.DIVERSITY_EQUITY_INCLUSION]: {
      get: () => getText('Diversity, Equity & Inclusion'),
      enumerable: true,
    },
    [JobFunction.EMPLOYEE_ENGAGEMENT]: {
      get: () => getText('Employee Engagement'),
      enumerable: true,
    },
    [JobFunction.EQUIPMENT_FACILITIES]: {
      get: () => getText('Equipment & Facilities'),
      enumerable: true,
    },
    [JobFunction.EVENTS]: { get: () => getText('Events'), enumerable: true },
    [JobFunction.PUBLIC_RELATIONS]: {
      get: () => getText('Public Relations'),
      enumerable: true,
    },
    [JobFunction.GRANTS_ADMINISTRATION]: {
      get: () => getText('Grants Administration'),
      enumerable: true,
    },
    [JobFunction.GRAPHIC_DESIGN]: {
      get: () => getText('Graphic Design'),
      enumerable: true,
    },
    [JobFunction.HEALTHCARE_PROVIDER_PRACTITIONER]: {
      get: () => getText('Healthcare Provider/Practitioner'),
      enumerable: true,
    },
    [JobFunction.HUMAN_RESOURCES]: {
      get: () => getText('Human Resources'),
      enumerable: true,
    },
    [JobFunction.INVESTIGATIONS]: {
      get: () => getText('Investigations'),
      enumerable: true,
    },
    [JobFunction.LEGAL]: { get: () => getText('Legal'), enumerable: true },
    [JobFunction.MARKETING]: {
      get: () => getText('Marketing'),
      enumerable: true,
    },
    [JobFunction.OPERATIONS]: {
      get: () => getText('Operations'),
      enumerable: true,
    },
    [JobFunction.PARTNERSHIPS]: {
      get: () => getText('Partnerships'),
      enumerable: true,
    },
    [JobFunction.PROGRAM_MANAGEMENT]: {
      get: () => getText('Program Management'),
      enumerable: true,
    },
    [JobFunction.RESEARCH]: {
      get: () => getText('Research'),
      enumerable: true,
    },
    [JobFunction.SAFETY_RESOURCE_PROTECTION]: {
      get: () => getText('Safety & Resource Protection'),
      enumerable: true,
    },
    [JobFunction.SOCIAL_MEDIA]: {
      get: () => getText('Social Media'),
      enumerable: true,
    },
    [JobFunction.STRATEGY_PLANNING]: {
      get: () => getText('Strategy & Planning'),
      enumerable: true,
    },
    [JobFunction.SUPPLIES_PROCUREMENT_DISTRIBUTION]: {
      get: () => getText('Supplies Procurement/Distribution'),
      enumerable: true,
    },
    [JobFunction.SUSTAINABILITY]: {
      get: () => getText('Sustainability'),
      enumerable: true,
    },
    [JobFunction.TEACHING_INSTRUCTION]: {
      get: () => getText('Teaching & Instruction'),
      enumerable: true,
    },
    [JobFunction.TECHNOLOGY_IT]: {
      get: () => getText('Technology/IT'),
      enumerable: true,
    },
    [JobFunction.WRITING_EDITORIAL]: {
      get: () => getText('Writing/Editorial'),
      enumerable: true,
    },
  },
) as Record<JobFunction, string>;

export const JOB_FUNCTIONS_RADIO_OR_SELECT_OPTIONS = () =>
  Object.entries(JOB_FUNCTIONS).map(([key, value]) => ({
    value: key,
    label: value,
  }));

export const JOB_FUNCTIONS_CHECKBOX_OPTIONS = () =>
  Object.entries(JOB_FUNCTIONS).map(([key, value]) => ({
    name: key,
    label: value,
  }));
