import { PropsWithChildren } from 'react';

import { Stack } from 'components/Stack/Stack';
import { useSearchFilters } from 'modules/search/hooks/filters/useSearchFilters';
import { spacing } from 'theme/theme';

import { SearchFiltersHeader } from './Header/SearchFiltersHeader';
import { SearchFiltersOverflowDropdown } from './OverflowDropdown/SearchFiltersOverflowDropdown';
import { SearchFiltersBody, SearchFiltersHolder } from './SearchFilters.styled';

export function SearchFilters({ children }: PropsWithChildren) {
  const { areFiltersActive, clearAllFilters, filtersRef } = useSearchFilters();

  return (
    <SearchFiltersHolder
      $locale={CURRENT_LOCALE()}
      ref={filtersRef}
      role="search"
      onSubmit={(e) => e.preventDefault()}
    >
      <Stack direction="row" spacing={spacing[24]}>
        <SearchFiltersHeader
          areFiltersActive={areFiltersActive}
          onClickClearAll={clearAllFilters}
        />
        <SearchFiltersBody className="search-filters-body">
          {children}
          <SearchFiltersOverflowDropdown>
            {children}
          </SearchFiltersOverflowDropdown>
        </SearchFiltersBody>
      </Stack>
    </SearchFiltersHolder>
  );
}
