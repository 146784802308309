import { useCallback, useMemo, useState } from 'react';
import { Outlet } from 'react-router';

import { RequireAuthentication } from 'containers/RequireAuthentication';
import { orgDashboardApiFetchOrg } from 'modules/orgDashboard/api/orgDashboardApiFetchOrg';
import { OrgDashboardApiOrg } from 'modules/orgDashboard/api/types/OrgDashboardApiOrg';
import {
  OrgDashboardOrgContext,
  OrgDashboardOrgContextState,
} from 'modules/orgDashboard/contexts/OrgDashboardOrgContext';

type Props = {
  initialOrg: OrgDashboardApiOrg;
};

export function OrgDashboardRouteGroupComponent({ initialOrg }: Props) {
  const [org, setOrg] = useState(initialOrg);

  const reloadOrg = useCallback(() => {
    orgDashboardApiFetchOrg(org.id).then((response) => setOrg(response.org));
  }, [org.id]);

  const contextValue = useMemo(
    () =>
      ({
        org,
        updateOrg: setOrg,
        reloadOrg,
      }) satisfies OrgDashboardOrgContextState,
    [org, reloadOrg],
  );

  return (
    <OrgDashboardOrgContext.Provider value={contextValue}>
      <RequireAuthentication>
        <Outlet />
      </RequireAuthentication>
    </OrgDashboardOrgContext.Provider>
  );
}
