export enum ProfessionalLevel {
  NONE = 'NONE',
  ENTRY_LEVEL = 'ENTRY_LEVEL',
  PROFESSIONAL = 'PROFESSIONAL',
  MANAGERIAL = 'MANAGERIAL',
  DIRECTOR = 'DIRECTOR',
  EXECUTIVE = 'EXECUTIVE',
}

export const PROFESSIONAL_LEVELS = Object.defineProperties(
  {},
  {
    NONE: { get: () => getText('None specified'), enumerable: true },
    ENTRY_LEVEL: { get: () => getText('Entry level'), enumerable: true },
    PROFESSIONAL: { get: () => getText('Mid-level'), enumerable: true },
    MANAGERIAL: { get: () => getText('Managerial'), enumerable: true },
    DIRECTOR: { get: () => getText('Director'), enumerable: true },
    EXECUTIVE: { get: () => getText('Executive'), enumerable: true },
  },
) as Record<ProfessionalLevel, string>;

export const PROFESSIONAL_LEVEL_CHECKBOX_OPTIONS = () =>
  Object.entries(PROFESSIONAL_LEVELS).map(([key, value]) => ({
    name: key,
    label: value,
  }));

export const PROFESSIONAL_RADIO_OR_SELECT_OPTIONS = () =>
  Object.entries(PROFESSIONAL_LEVELS).map(([key, value]) => ({
    value: key,
    label: value,
  }));
