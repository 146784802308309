import { ApiS3File } from 'api/types/ApiS3File';
import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import { OrgDashboardApiListingApplicationAttachment } from 'modules/orgDashboard/api/types/OrgDashboardApiListingApplicationAttachment';
import { UserDashboardApiListingApplicationMini } from 'modules/userDashboard/api/types/UserDashboardApiListingApplicationMini';
import { getJson } from 'utils/http/getJson';

type UserDashboardApiListingApplicationUser = {
  id: string;
  name: string;
  email: string;
};

export type UserDashboardApiListingApplication = {
  id: string;
  created: ApiISODateTimeString;
  user: UserDashboardApiListingApplicationUser;
  listing: UserDashboardApiListingApplicationMini['listing'];
  org: UserDashboardApiListingApplicationMini['org'];
  attachments: {
    resume?: ApiS3File;
    requestedAttachments?: OrgDashboardApiListingApplicationAttachment[];
  };
};

export async function userDashboardApiFetchListingApplication(
  applicationId: string,
): Promise<{ listingApplication: UserDashboardApiListingApplication }> {
  return getJson<{ listingApplication: UserDashboardApiListingApplication }>(
    `/data/listing-applications/${applicationId}`,
  );
}
