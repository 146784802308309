import { ApiOrgId } from 'api/types/ApiTypedId';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

type ApiResponse = {
  // TODO: add proper types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  report: Record<string, any>;
};

function mapResponse(response: ApiResponse) {
  return response.report;
}

const endpoint = new FetchEndpoint({
  urlFactory: (orgId: ApiOrgId) =>
    `/data/orgdashboard/${orgId}/payment-summary`,
  mapResponse,
});

// Exports

export const orgDashboardApiFetchPaymentSummary = endpoint.createFetchFn();
