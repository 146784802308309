export type ActionTypePlural = Record<
  'VOLOP' | 'LOAN' | 'DONATION' | 'PETITION',
  string
>;

export const ACTION_TYPE_PLURAL = Object.defineProperties(
  {},
  {
    VOLOP: { get: () => getText('Volunteering'), enumerable: true }, // TRANSLATORS: "Volunteer +" type
    LOAN: { get: () => getText('Loans'), enumerable: true }, // TRANSLATORS: "Volunteer +" type
    DONATION: { get: () => getText('Donations'), enumerable: true }, // TRANSLATORS: "Volunteer +" type
    PETITION: { get: () => getText('Petitions'), enumerable: true }, // TRANSLATORS: "Volunteer +" type
  },
) as ActionTypePlural;
