// Ensure the experiment has year_month in it to avoid future collision
// Note: The _20 is there to get types working, just `${number}` does not work
type ApiABExperimentIdFormat = `${string}_20${number}_${number}`;

type ApiABExperimentDefinition<TBucket extends string> = {
  buckets: TBucket[];
  defaultBucket: TBucket;
};

// List of AB experiments
export const apiABExperiments = {
  // This is an old experiment, please do not add new experiments that don't have _year_month on their ids
  [`OLD_CART_MIGRATION` as ApiABExperimentIdFormat]: {
    buckets: ['OLD', 'NEW'],
    defaultBucket: 'NEW',
  } as const,
  EARLY_APPLICANT_LABEL_2024_11: {
    buckets: ['ENABLED', 'DISABLED'],
    defaultBucket: 'DISABLED',
  },
  SPLIT_SCREEN_SEARCH_INACTIVE_2024_12: {
    buckets: ['ENABLED', 'DISABLED'],
    defaultBucket: 'DISABLED',
  },
} satisfies Record<ApiABExperimentIdFormat, ApiABExperimentDefinition<string>>;
