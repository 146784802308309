import { TextBadge } from 'components/Badge/TextBadge';
import { SearchFilterAccordionItem } from 'modules/search/components/Filters/AccordionItem/SearchFilterAccordionItem';
import { SearchFilterDropdown } from 'modules/search/components/Filters/Dropdown/SearchFilterDropdown';
import { SearchFilterCheckboxFacetOptions } from 'modules/search/components/Filters/FacetOptions/SearchFilterCheckboxFacetOptions';
import { useSearchFacetSelectedValues } from 'modules/search/hooks/filters/useSearchFacetSelectedValues';
import { SearchFilterVariant } from 'modules/search/types/SearchFilterVariant';
import { AREAS_OF_FOCUS_CHECKBOX_OPTIONS } from 'utils/constants/general/areasOfFocus';

type Props = {
  variant?: SearchFilterVariant;
};

export function OrgAreasOfFocusFilter({ variant = 'dropdown' }: Props) {
  const selectedFilterValues = useSearchFacetSelectedValues('areasOfFocus');

  const FilterVariant =
    variant === 'accordion' ? SearchFilterAccordionItem : SearchFilterDropdown;

  return (
    <FilterVariant
      facetType="areasOfFocus"
      icon="globe"
      label={
        <>
          {getText('Issue Area')}{' '}
          <TextBadge content={selectedFilterValues.length} />
        </>
      }
    >
      <SearchFilterCheckboxFacetOptions
        facetType="areasOfFocus"
        initialValue={selectedFilterValues}
        options={AREAS_OF_FOCUS_CHECKBOX_OPTIONS()}
        searchable
      />
    </FilterVariant>
  );
}
