export enum LocationType {
  ONSITE = 'ONSITE',
  HYBRID = 'HYBRID',
  REMOTE = 'REMOTE',
}

export const LOCATION_TYPE = Object.defineProperties(
  {},
  {
    // TRANSLATORS: listing location type
    [LocationType.ONSITE]: { get: () => getText('On-site'), enumerable: true },
    // TRANSLATORS: listing location type
    [LocationType.HYBRID]: { get: () => getText('Hybrid'), enumerable: true },
    // TRANSLATORS: listing location type
    [LocationType.REMOTE]: { get: () => getText('Remote'), enumerable: true },
  },
) as Record<LocationType, string>;

export const LOCATION_TYPE_CHECKBOX_OPTIONS = () =>
  Object.entries(LOCATION_TYPE).map(([key, value]) => ({
    name: key,
    label: value,
  }));
