import invariant from 'invariant';
import { createContext, useContext } from 'react';

import { OrgDashboardApiOrg } from 'modules/orgDashboard/api/types/OrgDashboardApiOrg';

export type OrgDashboardOrgContextState = {
  org: OrgDashboardApiOrg;
  updateOrg: (newOrg: OrgDashboardApiOrg) => void;
  reloadOrg: () => void;
};

export const OrgDashboardOrgContext =
  createContext<OrgDashboardOrgContextState>({
    org: null as unknown as OrgDashboardApiOrg,
    updateOrg: () => {},
    reloadOrg: () => {},
  });

export function useOrgDashboardOrgContext() {
  const contextValue = useContext(OrgDashboardOrgContext);

  invariant(
    contextValue !== null,
    'Attempting to consume org before it is set.',
  );

  return contextValue;
}
