import styled from 'styled-components';

import { detailsZeroWhenClosedOneWhenOpenVar } from 'components/Details/detailsCssVariables';
import { colors, spacing } from 'theme/theme';

export const SearchFilterAccordionSummary = styled.summary`
  align-items: center;
  border-bottom: 1px solid ${colors.selectionGrey};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  margin-bottom: calc(
    var(${detailsZeroWhenClosedOneWhenOpenVar}) * ${spacing[16]}
  );
  padding: ${spacing[24]} 0;
  position: relative;
  justify-content: space-between;

  &::marker {
    content: '';
  }

  &::-webkit-details-marker /* Safari */ {
    display: none;
  }
`;

export const SearchFilterAccordionSummaryLabel = styled.div`
  align-items: center;
  display: flex;
  gap: ${spacing[8]};
`;
