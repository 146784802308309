import { RouteObject } from 'react-router';

import { NotFoundContent } from 'components/NotFoundContent/NotFoundContent';
import { LayoutCenteredBody } from 'layouts/Layout/LayoutCenteredBody';
import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { OrgDashboardCheckoutCartPageLoadable } from 'modules/orgDashboard/checkout/pages/Cart/OrgDashboardCheckoutCartPageLoadable';
import { OrgDashboardCreateEventPageContainerLoadable } from 'modules/orgDashboard/pages/events/create/OrgDashboardCreateEventPageContainerLoadable';
import { CreateJobPageContainerLoadable } from 'modules/orgDashboard/pages/jobs/create/CreateJobPageContainerLoadable';
import { OrgDashboardPostAListingPageContainerLoadable } from 'modules/orgDashboard/pages/listings/post/OrgDashboardPostAListingPageContainerLoadable';
import { OrgDashboardCreateVolopPageContainerLoadable } from 'modules/orgDashboard/pages/volops/create/OrgDashboardCreateVolopPageContainerLoadable';
import { loadableLoader } from 'routing/utils/loadableLoader';

import {
  orgDashboardCheckoutCartRoute,
  orgDashboardCreateEventRoute,
  orgDashboardCreateInternshipRoute,
  orgDashboardCreateJobRoute,
  orgDashboardCreateVolopRoute,
  orgDashboardPostAListingRoute,
} from './orgDashboard.routes';

export const noSubLayoutBaseOrgDashboardRouteObjects = [
  {
    handle: { darkBackground: true } satisfies LayoutHandleProps,
    path: orgDashboardPostAListingRoute.relativePath,
    loader: loadableLoader(OrgDashboardPostAListingPageContainerLoadable),
    Component: OrgDashboardPostAListingPageContainerLoadable,
  },
  {
    handle: { darkBackground: true } satisfies LayoutHandleProps,
    path: orgDashboardCreateJobRoute.relativePath,
    loader: loadableLoader(CreateJobPageContainerLoadable),
    element: (
      <LayoutCenteredBody>
        <CreateJobPageContainerLoadable listingType="JOB" />
      </LayoutCenteredBody>
    ),
  },
  {
    handle: { darkBackground: true } satisfies LayoutHandleProps,
    loader: loadableLoader(CreateJobPageContainerLoadable),
    path: orgDashboardCreateInternshipRoute.relativePath,
    element: (
      <LayoutCenteredBody>
        <CreateJobPageContainerLoadable listingType="INTERNSHIP" />
      </LayoutCenteredBody>
    ),
  },
  {
    handle: { darkBackground: true } satisfies LayoutHandleProps,
    loader: loadableLoader(OrgDashboardCreateVolopPageContainerLoadable),
    path: orgDashboardCreateVolopRoute.relativePath,
    element: (
      <LayoutCenteredBody>
        <OrgDashboardCreateVolopPageContainerLoadable />
      </LayoutCenteredBody>
    ),
  },
  {
    handle: { darkBackground: true } satisfies LayoutHandleProps,
    loader: loadableLoader(OrgDashboardCreateEventPageContainerLoadable),
    path: orgDashboardCreateEventRoute.relativePath,
    element: (
      <LayoutCenteredBody>
        <OrgDashboardCreateEventPageContainerLoadable />
      </LayoutCenteredBody>
    ),
  },
  {
    path: orgDashboardCheckoutCartRoute.relativePath,
    loader: loadableLoader(OrgDashboardCheckoutCartPageLoadable),
    Component: OrgDashboardCheckoutCartPageLoadable,
  },
  {
    path: '*',
    element: <NotFoundContent textOnly />,
  },
] satisfies RouteObject[];
