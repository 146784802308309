import { CSSProperties } from 'react';
import styled from 'styled-components';

import {
  ViewportBreakpoint,
  breakpointValues,
  colors,
  spacing,
  transition,
} from 'theme/theme';

import { PillSize } from './types/PillSize';

// We could make this more generalized if we find ourselves wanting to generate
// multiple variants for multiple breakpoints more frequently.
const generatePillSizeStyles = (
  size: PillSize | Partial<Record<ViewportBreakpoint, PillSize>>,
) => {
  const pillSizeStyles: Record<PillSize, CSSProperties> = {
    small: {
      fontSize: '14px',
      borderRadius: '20px',
      height: '36px',
      padding: `0 ${spacing[16]}`,
    },
    medium: {
      fontSize: '16px',
      borderRadius: '31px',
      height: '36px',
      padding: `0 ${spacing[16]}`,
    },
    large: {
      fontSize: '20px',
      borderRadius: '31px',
      height: '46px',
      padding: `0 ${spacing[16]}`,
    },
  };

  let styles = '';
  let firstBreakpointSet = false;

  if (typeof size === 'string') {
    const { height, fontSize, borderRadius, padding } = pillSizeStyles[size];
    styles += `
      font-size: ${fontSize};
      border-radius: ${borderRadius};
      padding: ${padding};
      height: ${height};
    `;
  }

  if (typeof size === 'object') {
    (Object.entries(size) as [ViewportBreakpoint, PillSize][]).forEach(
      ([breakpoint, sizeAtBreakpoint]) => {
        const { fontSize, borderRadius, height, padding } =
          pillSizeStyles[sizeAtBreakpoint];
        if (!firstBreakpointSet) {
          styles += `
          font-size: ${fontSize};
          border-radius: ${borderRadius};
          height: ${height};
          padding: ${padding};
        `;
          firstBreakpointSet = true;
        } else {
          const breakpointValue = breakpointValues[breakpoint];
          styles += `
          @media (min-width: ${breakpointValue}px) {
              font-size: ${fontSize};
              border-radius: ${borderRadius};
              padding: ${padding};
              height: ${height};
          }
        `;
        }
      },
    );
  }

  return styles;
};
export const PillHolder = styled.span<{
  $size: PillSize | Partial<Record<ViewportBreakpoint, PillSize>>;
}>`
  align-items: center;
  display: inline-flex;
  gap: ${spacing[8]};
  line-height: 1;
  transition: background ${transition};

  &[data-variant='default'] {
    background: ${colors.backgroundGrey};
    border: 1px solid ${colors.elementGrey};
    color: ${colors.lightContentGrey};
  }

  &[data-variant='outline'] {
    background-color: transparent;
    color: ${colors.lightContentGrey};

    border: 1px solid ${colors.selectionGrey};
  }

  ${({ $size }) => generatePillSizeStyles($size)}

  &[data-clickable] {
    cursor: pointer;

    &:hover {
      background-color: ${colors.selectionBlue};
      color: ${colors.brandBlue};
    }
  }
`;

export const PillPrefixHolder = styled.span`
  display: flex;

  &:empty {
    display: none;
  }
`;

export const PillSuffixHolder = styled.span`
  display: flex;

  &:empty {
    display: none;
  }
`;

export const PillText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const PillCloseButton = styled.button`
  border-radius: 50%;
  display: flex;
  line-height: inherit;
  color: ${colors.lightContentGrey};
  transition: all ${transition};

  &:hover {
    background-color: ${colors.selectionBlue};
    color: ${colors.brandBlue};
  }
`;
