import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { ORG_TYPES } from 'utils/constants/general/orgTypes';

export const orgTypeFacet = {
  id: 'orgTypeFacet',
  name: 'orgType',
  get title() {
    return getText('Org Type');
  },
  type: 'array',
  options: Object.keys(ORG_TYPES),
  optionTitles: ORG_TYPES,
  sort: false,
} as const satisfies SearchFacetData;
