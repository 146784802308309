import { RouteObject } from 'react-router';

import { ApiOrgId } from 'api/types/ApiTypedId';
import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardApiFetchOrg } from 'modules/orgDashboard/api/orgDashboardApiFetchOrg';
import { orgDashboardApiFetchPaymentSummary } from 'modules/orgDashboard/api/payment/orgDashboardApiFetchPaymentSummary';
import { OrgDashboardManageAdminsPageContainerLoadable } from 'modules/orgDashboard/pages/admins/manage/OrgDashboardManageAdminsPageContainerLoadable';
import { OrgDashboardApiAccessContainerLoadable } from 'modules/orgDashboard/pages/apiAccess/manage/OrgDashboardApiAccessContainerLoadable';
import { OrgDashboardCheckoutBillingAddressContainerLoadable } from 'modules/orgDashboard/pages/checkout/billingAddress/OrgDashboardCheckoutBillingAddressContainerLoadable';
import { OrgDashboardCartContainerLoadable } from 'modules/orgDashboard/pages/checkout/cart/OrgDashboardCartContainerLoadable';
import { OrgDashboardPayByCreditCardContainerLoadable } from 'modules/orgDashboard/pages/checkout/payByCreditCard/OrgDashboardPayByCreditCardContainerLoadable';
import { OrgDashboardBuyCreditsContainerLoadable } from 'modules/orgDashboard/pages/credits/buy/OrgDashboardBuyCreditsContainerLoadable';
import { OrgDashboardCreditsListContainerLoadable } from 'modules/orgDashboard/pages/credits/list/OrgDashboardCreditsListContainerLoadable';
import { OrgDashboardCreditViewContainerLoadable } from 'modules/orgDashboard/pages/credits/view/OrgDashboardCreditViewContainerLoadable';
import { OrgDashboardEditEventPageContainerLoadable } from 'modules/orgDashboard/pages/events/edit/OrgDashboardEditEventPageContainerLoadable';
import { OrgDashboardRepostEventPageContainerLoadable } from 'modules/orgDashboard/pages/events/repost/OrgDashboardRepostEventPageContainerLoadable';
import { OrgDashboardGradFairHomeContainerLoadable } from 'modules/orgDashboard/pages/gradFairs/home/OrgDashboardGradFairHomeContainerLoadable';
import { OrgDashboardGradFairInviteContainerLoadable } from 'modules/orgDashboard/pages/gradFairs/invite/OrgDashboardGradFairInviteContainerLoadable';
import { OrgDashboardIntegrationsPageContainerLoadable } from 'modules/orgDashboard/pages/integrations/manage/OrgDashboardIntegrationsPageContainerLoadable';
import { OrgDashboardEditInternshipPageContainerLoadable } from 'modules/orgDashboard/pages/internships/edit/OrgDashboardEditInternshipPageContainerLoadable';
import { OrgDashboardRepostInternshipPageContainerLoadable } from 'modules/orgDashboard/pages/internships/repost/OrgDashboardRepostInternshipPageContainerLoadable';
import { OrgDashboardInvoiceBillingAddressContainerLoadable } from 'modules/orgDashboard/pages/invoices/billingAddress/OrgDashboardInvoiceBillingAddressContainerLoadable';
import { InvoicesListContainerLoadable } from 'modules/orgDashboard/pages/invoices/list/InvoicesListContainerLoadable';
import { OrgDashboardInvoiceViewPageContainerLoadable } from 'modules/orgDashboard/pages/invoices/view/OrgDashboardInvoiceViewPageContainerLoadable';
import { OrgDashboardEditJobPageContainerLoadable } from 'modules/orgDashboard/pages/jobs/edit/OrgDashboardEditJobPageContainerLoadable';
import { OrgDashboardRepostJobPageContainerLoadable } from 'modules/orgDashboard/pages/jobs/repost/OrgDashboardRepostJobPageContainerLoadable';
import { ManageListingsPageLoadable } from 'modules/orgDashboard/pages/listings/manage/ManageListingsPageLoadable';
import { OrgDashboardNotificationPreferencesLoadable } from 'modules/orgDashboard/pages/notificationPreferences/manage/OrgDashboardNotificationPreferencesLoadable';
import { OrgDashboardEditOrgPageLoadable } from 'modules/orgDashboard/pages/orgs/edit/OrgDashboardEditOrgPageLoadable';
import { OrgDashboardPaymentsListContainerLoadable } from 'modules/orgDashboard/pages/payments/list/OrgDashboardPaymentsListContainerLoadable';
import { OrgDashboardPaymentSummaryPageLoadable } from 'modules/orgDashboard/pages/payments/summary/OrgDashboardPaymentSummaryPageLoadable';
import { OrgDasboardViewPaymentPageLoadable } from 'modules/orgDashboard/pages/payments/view/OrgDasboardViewPaymentPageLoadable';
import { OrgDashboardEditVolopPageContainerLoadable } from 'modules/orgDashboard/pages/volops/edit/OrgDashboardEditVolopPageContainerLoadable';
import { OrgDashboardRepostVolopPageContainerLoadable } from 'modules/orgDashboard/pages/volops/repost/OrgDashboardRepostVolopPageContainerLoadable';
import { OrgDashboardSidebarSubLayoutLoadable } from 'modules/orgDashboard/sub-layouts/Sidebar/OrgDashboardSidebarSubLayoutLoadable';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import { AppRouteGroupLoaderArgs } from 'routing/classes/types/AppRouteGroupLoaderArgs';
import { AppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import { loadableLoader } from 'routing/utils/loadableLoader';
import { renderLoader } from 'routing/utils/renderLoader/renderLoader';
import { log } from 'utils/logging';

import { OrgDashboardRouteGroupComponent } from './OrgDashboardRouteGroupComponent';
import { noSubLayoutOrgDashboardRouteObjects } from './noSubLayout.orgDashboard.route-objects';
import { noSubLayoutBaseOrgDashboardRouteObjects } from './noSubLayoutBase.orgDashboard.route-objects';
import { orgSelectRouteObjects } from './org-select.route-objects';
import {
  orgDashboardApiAccessRoute,
  orgDashboardBuyCreditPacksRoute,
  orgDashboardCartRoute,
  orgDashboardCheckoutBillingAddressRoute,
  orgDashboardCreditsRoute,
  orgDashboardEditEventRoute,
  orgDashboardEditInternshipRoute,
  orgDashboardEditJobRoute,
  orgDashboardEditOrgRoute,
  orgDashboardEditVolopRoute,
  orgDashboardGradFairsHomeRoute,
  orgDashboardIntegrationRoute,
  orgDashboardIntegrationsRoute,
  orgDashboardInvoiceBillingAddressRoute,
  orgDashboardListInvoicesRoute,
  orgDashboardListPaymentsRoute,
  orgDashboardManageAdminsRoute,
  orgDashboardManageListingsRoute,
  orgDashboardNotificationPreferencesRoute,
  orgDashboardPayByCreditCartRoute,
  orgDashboardPaymentSummaryRoute,
  orgDashboardRepostEventRoute,
  orgDashboardRepostInternshipRoute,
  orgDashboardRepostJobRoute,
  orgDashboardRepostVolopRoute,
  orgDashboardRouteGroup,
  orgDashboardViewCreditPackRoute,
  orgDashboardViewGradFairInviteRoute,
  orgDashboardViewInvoiceRoute,
  orgDashboardViewPaymentRoute,
} from './orgDashboard.routes';

export const orgDashboardRouteObjects = [
  ...orgSelectRouteObjects,
  {
    path: orgDashboardRouteGroup.wrapperPath,
    ...renderLoader({
      loader: async ({
        params,
        request,
      }: AppRouteGroupLoaderArgs<typeof orgDashboardRouteGroup>) => {
        const orgId = params.orgId as ApiOrgId;

        try {
          // On very rare occasions the org SSR API data is not set Hypernova crashes,
          // failing to respond to subsequent requests.
          //
          // This loader used to rely on a zustand store and has been refactored. In theory
          // this error handling should not be necessary, but just in case the issue happens
          // again, we have some special error handling to prevent Hypernova from crashing.
          //
          // If this error does not happen until July 2025, we can remove this try/catch
          //
          // https://www.pivotaltracker.com/story/show/188866705
          const { org } = await orgDashboardApiFetchOrg(orgId);
          return org;
        } catch {
          return { type: 'error', orgId, url: request.url };
        }
      },
      render: (orgOrError) => {
        if (orgOrError.type === 'error') {
          log.error(
            `Unable to find org with id "${orgOrError.orgId}" on url ${orgOrError.url}`,
          );
          return <NotFoundPageLoadable noLayout />;
        }

        const org = orgOrError;

        return (
          <OrgDashboardRouteGroupComponent key={org.id} initialOrg={org} />
        );
      },
    }),
    shouldRevalidate: ({ currentParams, nextParams }) =>
      currentParams.orgId !== nextParams.orgId,
    children: [
      // Org Dashboard Sidebar Sub Layout
      {
        Component: OrgDashboardSidebarSubLayoutLoadable,
        loader: loadableLoader(OrgDashboardSidebarSubLayoutLoadable),
        handle: {
          fallbackUrlByLocaleToCurrent: true,
          darkBackground: true,
          showDesktopSearch: true,
        } satisfies LayoutHandleProps,
        children: [
          {
            path: orgDashboardManageListingsRoute.relativePath,
            loader: loadableLoader(ManageListingsPageLoadable),
            Component: ManageListingsPageLoadable,
          },
          {
            path: orgDashboardEditOrgRoute.relativePath,
            loader: loadableLoader(OrgDashboardEditOrgPageLoadable),
            Component: OrgDashboardEditOrgPageLoadable,
          },
          {
            path: orgDashboardManageAdminsRoute.relativePath,
            loader: loadableLoader(
              OrgDashboardManageAdminsPageContainerLoadable,
            ),
            Component: OrgDashboardManageAdminsPageContainerLoadable,
          },
          {
            path: orgDashboardEditJobRoute.relativePath,
            loader: loadableLoader(OrgDashboardEditJobPageContainerLoadable),
            Component: OrgDashboardEditJobPageContainerLoadable,
          },
          {
            path: orgDashboardRepostJobRoute.relativePath,
            loader: loadableLoader(OrgDashboardRepostJobPageContainerLoadable),
            Component: OrgDashboardRepostJobPageContainerLoadable,
          },
          {
            path: orgDashboardEditInternshipRoute.relativePath,
            loader: loadableLoader(
              OrgDashboardEditInternshipPageContainerLoadable,
            ),
            Component: OrgDashboardEditInternshipPageContainerLoadable,
          },
          {
            path: orgDashboardRepostInternshipRoute.relativePath,
            loader: loadableLoader(
              OrgDashboardRepostInternshipPageContainerLoadable,
            ),
            Component: OrgDashboardRepostInternshipPageContainerLoadable,
          },
          {
            path: orgDashboardEditVolopRoute.relativePath,
            loader: loadableLoader(OrgDashboardEditVolopPageContainerLoadable),
            Component: OrgDashboardEditVolopPageContainerLoadable,
          },
          {
            path: orgDashboardRepostVolopRoute.relativePath,
            loader: loadableLoader(
              OrgDashboardRepostVolopPageContainerLoadable,
            ),
            Component: OrgDashboardRepostVolopPageContainerLoadable,
          },
          {
            path: orgDashboardEditEventRoute.relativePath,
            loader: loadableLoader(OrgDashboardEditEventPageContainerLoadable),
            Component: OrgDashboardEditEventPageContainerLoadable,
          },
          {
            path: orgDashboardRepostEventRoute.relativePath,
            loader: loadableLoader(
              OrgDashboardRepostEventPageContainerLoadable,
            ),
            Component: OrgDashboardRepostEventPageContainerLoadable,
          },
          {
            path: orgDashboardListInvoicesRoute.relativePath,
            loader: loadableLoader(InvoicesListContainerLoadable),
            Component: InvoicesListContainerLoadable,
            // Don't revalidate when search params change, as they won't influence this loader
            shouldRevalidate: () => false,
          },
          {
            path: orgDashboardViewInvoiceRoute.relativePath,
            loader: loadableLoader(
              OrgDashboardInvoiceViewPageContainerLoadable,
            ),
            Component: OrgDashboardInvoiceViewPageContainerLoadable,
          },
          {
            path: orgDashboardInvoiceBillingAddressRoute.relativePath,
            loader: loadableLoader(
              OrgDashboardInvoiceBillingAddressContainerLoadable,
            ),
            Component: OrgDashboardInvoiceBillingAddressContainerLoadable,
          },
          {
            path: orgDashboardViewPaymentRoute.relativePath,
            loader: loadableLoader(OrgDasboardViewPaymentPageLoadable),
            Component: OrgDasboardViewPaymentPageLoadable,
          },
          {
            path: orgDashboardListPaymentsRoute.relativePath,
            loader: loadableLoader(OrgDashboardPaymentsListContainerLoadable),
            Component: OrgDashboardPaymentsListContainerLoadable,
            // Don't revalidate when search params change, as they won't influence this loader
            shouldRevalidate: () => false,
          },
          {
            path: orgDashboardPaymentSummaryRoute.relativePath,
            ...renderLoader({
              clientOnly: true,
              loader: async ({
                params,
              }: AppRouteLoaderArgs<
                typeof orgDashboardPaymentSummaryRoute
              >) => {
                const [report] = await Promise.all([
                  orgDashboardApiFetchPaymentSummary(params.orgId as ApiOrgId),
                  loadableLoader(OrgDashboardPaymentSummaryPageLoadable),
                ]);

                return report;
              },
              render: (report) => (
                <OrgDashboardPaymentSummaryPageLoadable report={report} />
              ),
            }),
          },
          {
            path: orgDashboardCheckoutBillingAddressRoute.relativePath,
            loader: loadableLoader(
              OrgDashboardCheckoutBillingAddressContainerLoadable,
            ),
            Component: OrgDashboardCheckoutBillingAddressContainerLoadable,
          },
          {
            path: orgDashboardPayByCreditCartRoute.relativePath,
            loader: loadableLoader(
              OrgDashboardPayByCreditCardContainerLoadable,
            ),
            Component: OrgDashboardPayByCreditCardContainerLoadable,
          },
          {
            path: orgDashboardCartRoute.relativePath,
            loader: loadableLoader(OrgDashboardCartContainerLoadable),
            Component: OrgDashboardCartContainerLoadable,
          },
          {
            path: orgDashboardBuyCreditPacksRoute.relativePath,
            loader: loadableLoader(OrgDashboardBuyCreditsContainerLoadable),
            Component: OrgDashboardBuyCreditsContainerLoadable,
          },
          {
            path: orgDashboardCreditsRoute.relativePath,
            loader: loadableLoader(OrgDashboardCreditsListContainerLoadable),
            Component: OrgDashboardCreditsListContainerLoadable,
          },
          {
            path: orgDashboardViewCreditPackRoute.relativePath,
            loader: loadableLoader(OrgDashboardCreditViewContainerLoadable),
            Component: OrgDashboardCreditViewContainerLoadable,
          },
          {
            path: orgDashboardApiAccessRoute.relativePath,
            loader: loadableLoader(OrgDashboardApiAccessContainerLoadable),
            Component: OrgDashboardApiAccessContainerLoadable,
          },
          {
            path: orgDashboardIntegrationsRoute.relativePath,
            loader: loadableLoader(
              OrgDashboardIntegrationsPageContainerLoadable,
            ),
            Component: OrgDashboardIntegrationsPageContainerLoadable,
          },
          {
            path: orgDashboardIntegrationRoute.relativePath,
            loader: loadableLoader(
              OrgDashboardIntegrationsPageContainerLoadable,
            ),
            Component: OrgDashboardIntegrationsPageContainerLoadable,
          },
          {
            path: orgDashboardViewGradFairInviteRoute.relativePath,
            loader: loadableLoader(OrgDashboardGradFairInviteContainerLoadable),
            Component: OrgDashboardGradFairInviteContainerLoadable,
          },
          {
            path: orgDashboardGradFairsHomeRoute.relativePath,
            loader: loadableLoader(OrgDashboardGradFairHomeContainerLoadable),
            Component: OrgDashboardGradFairHomeContainerLoadable,
          },
          {
            path: orgDashboardNotificationPreferencesRoute.relativePath,
            loader: loadableLoader(OrgDashboardNotificationPreferencesLoadable),
            Component: OrgDashboardNotificationPreferencesLoadable,
          },
        ],
      },
      // No Sub Layout
      {
        handle: {
          fallbackUrlByLocaleToCurrent: true,
          darkBackground: true,
          showDesktopSearch: true,
        } satisfies LayoutHandleProps,
        children: noSubLayoutOrgDashboardRouteObjects,
      },
      // No Sub Layout, 'base' Layout variant (these need to be migrated)
      {
        handle: { variant: 'base' } satisfies LayoutHandleProps,
        children: noSubLayoutBaseOrgDashboardRouteObjects,
      },
    ],
  },
] satisfies RouteObject[];
