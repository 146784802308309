import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { EMPLOYMENT_TYPES } from 'utils/constants/general/employmentTypes';

export const employmentTypeFacet = {
  id: 'employmentTypeFacet',
  name: 'jobType',
  get title() {
    return getText('Job Type');
  },
  type: 'array',
  options: Object.keys(EMPLOYMENT_TYPES),
  optionTitles: EMPLOYMENT_TYPES,
} as const satisfies SearchFacetData;
