export enum InternshipFunction {
  ACCOUNTING_FINANCE = 'ACCOUNTING_FINANCE',
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  ADVOCACY = 'ADVOCACY',
  BUSINESS_DEVELOPMENT = 'BUSINESS_DEVELOPMENT',
  CITIZENSHIP = 'CITIZENSHIP',
  COMMUNITY_BELONGING = 'COMMUNITY_BELONGING',
  COMMUNITY_ENGAGEMENT_OUTREACH = 'COMMUNITY_ENGAGEMENT_OUTREACH',
  CONSERVATION = 'CONSERVATION',
  CORPORATE_PHILANTHROPY = 'CORPORATE_PHILANTHROPY',
  CORPORATE_RESPONSIBILITY = 'CORPORATE_RESPONSIBILITY',
  DATA_ENTRY_ANALYSIS = 'DATA_ENTRY_ANALYSIS',
  DEVELOPMENT_FUNDRAISING = 'DEVELOPMENT_FUNDRAISING',
  DIVERSITY_EQUITY_INCLUSION = 'DIVERSITY_EQUITY_INCLUSION',
  EMPLOYEE_ENGAGEMENT = 'EMPLOYEE_ENGAGEMENT',
  EVENTS = 'EVENTS',
  GRANT_WRITING = 'GRANT_WRITING',
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
  LEGAL = 'LEGAL',
  MARKETING_COMMUNICATIONS = 'MARKETING_COMMUNICATIONS',
  OPERATIONS = 'OPERATIONS',
  PARTNERSHIPS = 'PARTNERSHIPS',
  PHOTOGRAPHY_MEDIA_DESIGN = 'PHOTOGRAPHY_MEDIA_DESIGN',
  PROGRAM_MANAGEMENT = 'PROGRAM_MANAGEMENT',
  PUBLIC_RELATIONS = 'PUBLIC_RELATIONS',
  RESEARCH = 'RESEARCH',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  STRATEGY = 'STRATEGY',
  SUSTAINABILITY = 'SUSTAINABILITY',
  TEACHING_INSTRUCTION = 'TEACHING_INSTRUCTION',
  TECHNOLOGY_IT = 'TECHNOLOGY_IT',
  WRITING_JOURNALISM = 'WRITING_JOURNALISM',
}

export const INTERNSHIP_FUNCTIONS = Object.defineProperties(
  {},
  {
    [InternshipFunction.ACCOUNTING_FINANCE]: {
      get: () => getText('Accounting & Finance'),
      enumerable: true,
    },
    [InternshipFunction.ADMINISTRATIVE]: {
      get: () => getText('Administrative'),
      enumerable: true,
    },
    [InternshipFunction.ADVOCACY]: {
      get: () => getText('Advocacy'),
      enumerable: true,
    },
    [InternshipFunction.BUSINESS_DEVELOPMENT]: {
      get: () => getText('Business Development'),
      enumerable: true,
    },
    [InternshipFunction.CITIZENSHIP]: {
      get: () => getText('Citizenship'),
      enumerable: true,
    },
    [InternshipFunction.COMMUNITY_BELONGING]: {
      get: () => getText('Community Belonging'),
      enumerable: true,
    },
    [InternshipFunction.COMMUNITY_ENGAGEMENT_OUTREACH]: {
      get: () => getText('Community Engagement/Outreach'),
      enumerable: true,
    },
    [InternshipFunction.CONSERVATION]: {
      get: () => getText('Conservation'),
      enumerable: true,
    },
    [InternshipFunction.CORPORATE_PHILANTHROPY]: {
      get: () => getText('Corporate Philanthropy'),
      enumerable: true,
    },
    [InternshipFunction.CORPORATE_RESPONSIBILITY]: {
      get: () => getText('Corporate Responsibility'),
      enumerable: true,
    },
    [InternshipFunction.DATA_ENTRY_ANALYSIS]: {
      get: () => getText('Data Entry & Analysis'),
      enumerable: true,
    },
    [InternshipFunction.DEVELOPMENT_FUNDRAISING]: {
      get: () => getText('Development/Fundraising'),
      enumerable: true,
    },
    [InternshipFunction.DIVERSITY_EQUITY_INCLUSION]: {
      get: () => getText('Diversity, Equity & Inclusion'),
      enumerable: true,
    },
    [InternshipFunction.EMPLOYEE_ENGAGEMENT]: {
      get: () => getText('Employee Engagement'),
      enumerable: true,
    },
    [InternshipFunction.EVENTS]: {
      get: () => getText('Events'),
      enumerable: true,
    },
    [InternshipFunction.GRANT_WRITING]: {
      get: () => getText('Grant Writing'),
      enumerable: true,
    },
    [InternshipFunction.HUMAN_RESOURCES]: {
      get: () => getText('Human Resources'),
      enumerable: true,
    },
    [InternshipFunction.LEGAL]: {
      get: () => getText('Legal'),
      enumerable: true,
    },
    [InternshipFunction.MARKETING_COMMUNICATIONS]: {
      get: () => getText('Marketing & Communications'),
      enumerable: true,
    },
    [InternshipFunction.OPERATIONS]: {
      get: () => getText('Operations'),
      enumerable: true,
    },
    [InternshipFunction.PARTNERSHIPS]: {
      get: () => getText('Partnerships'),
      enumerable: true,
    },
    [InternshipFunction.PHOTOGRAPHY_MEDIA_DESIGN]: {
      get: () => getText('Photography, Media & Design'),
      enumerable: true,
    },
    [InternshipFunction.PROGRAM_MANAGEMENT]: {
      get: () => getText('Program Management'),
      enumerable: true,
    },
    [InternshipFunction.PUBLIC_RELATIONS]: {
      get: () => getText('Public Relations'),
      enumerable: true,
    },
    [InternshipFunction.RESEARCH]: {
      get: () => getText('Research'),
      enumerable: true,
    },
    [InternshipFunction.SOCIAL_MEDIA]: {
      get: () => getText('Social Media'),
      enumerable: true,
    },
    [InternshipFunction.STRATEGY]: {
      get: () => getText('Strategy'),
      enumerable: true,
    },
    [InternshipFunction.SUSTAINABILITY]: {
      get: () => getText('Sustainability'),
      enumerable: true,
    },
    [InternshipFunction.TEACHING_INSTRUCTION]: {
      get: () => getText('Teaching & Instruction'),
      enumerable: true,
    },
    [InternshipFunction.TECHNOLOGY_IT]: {
      get: () => getText('Technology/IT'),
      enumerable: true,
    },
    [InternshipFunction.WRITING_JOURNALISM]: {
      get: () => getText('Writing or Journalism'),
      enumerable: true,
    },
  },
) as Record<InternshipFunction, string>;

export const INTERNSHIP_FUNCTIONS_OPTIONS = () =>
  Object.entries(INTERNSHIP_FUNCTIONS).map(([key, value]) => ({
    value: key,
    label: value,
  }));
