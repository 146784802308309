export enum OrgType {
  NONPROFIT = 'NONPROFIT',
  GOVERNMENT = 'GOVERNMENT',
  CORPORATION = 'CORPORATION',
  CONSULTANT = 'CONSULTANT',
  RECRUITER = 'RECRUITER',
  COMMUNITY = 'COMMUNITY',
}

export const ORG_TYPES = Object.defineProperties(
  {},
  {
    [OrgType.NONPROFIT]: {
      get: () => getText('Nonprofit'),
      enumerable: true,
    },
    [OrgType.GOVERNMENT]: {
      get: () => getText('Government'),
      enumerable: true,
    },
    [OrgType.CORPORATION]: { get: () => getText('Business'), enumerable: true },
    [OrgType.CONSULTANT]: {
      get: () => getText('Consultant'),
      enumerable: true,
    },
    [OrgType.RECRUITER]: { get: () => getText('Recruiter'), enumerable: true },
    [OrgType.COMMUNITY]: {
      get: () => getText('Community Group'),
      enumerable: true,
    },
  },
) as Record<OrgType, string>;

export const ORG_TYPE_CHECKBOX_OPTIONS = () =>
  Object.entries(ORG_TYPES).map(([key, value]) => ({
    name: key,
    label: value,
  }));

export const ORG_TYPE_RADIO_OR_SELECT_OPTIONS = () =>
  Object.entries(ORG_TYPES).map(([key, value]) => ({
    value: key,
    label: value,
  }));
