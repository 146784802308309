import { useCallback, useRef } from 'react';
import { useSearchParams } from 'react-router';

import { useDeferredNavigate } from 'hooks/useDeferredNavigate';
import { useLatest } from 'hooks/useLatest';
import {
  SEARCH_JOBS_FILTERS,
  SearchJobsFilterKeys,
} from 'modules/search/constants/filters/searchJobsFilters';
import { searchGetRoutePathSwitch } from 'modules/search/routing/helpers/searchGetRoutePathSwitch';
import { useSearchFiltersByName } from 'modules/search/zustand-stores/searchStore';

export function useSearchFilters() {
  const filtersRef = useRef<HTMLFormElement | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useDeferredNavigate();
  const searchParamsRef = useLatest<URLSearchParams>(searchParams);
  const searchFiltersByName = useSearchFiltersByName();

  const areFiltersActive =
    SEARCH_JOBS_FILTERS.some((filter) => searchParams.has(filter)) ||
    (searchFiltersByName &&
      Object.values(searchFiltersByName).some((v) => {
        if (v === searchFiltersByName.type) return false;
        return Array.isArray(v) ? v.length > 0 : v;
      }));

  const clearAllFilters = useCallback(() => {
    ([...searchParamsRef.current.keys()] as SearchJobsFilterKeys[]).forEach(
      (key) => {
        if (SEARCH_JOBS_FILTERS.includes(key)) {
          searchParamsRef.current.delete(key);
        }
      },
    );

    const newRoute = searchGetRoutePathSwitch({
      lang: CURRENT_LOCALE(),
      q: searchParamsRef.current.get('q') || '',
      filtersByName: { type: searchFiltersByName?.type || 'JOB' },
    });

    navigate(newRoute, { replace: true });

    filtersRef.current
      ?.querySelectorAll('[data-facet-state="PINNED"]')
      .forEach((node) => {
        node.setAttribute('data-facet-state', 'UNPINNED');
      });

    filtersRef.current
      ?.querySelectorAll('input[type="checkbox"]')
      .forEach((option) => {
        if (option instanceof HTMLInputElement) {
          // eslint-disable-next-line no-param-reassign
          option.checked = false;
        }
      });

    filtersRef.current
      ?.querySelectorAll('input[type="radio"]')
      .forEach((option) => {
        if (option instanceof HTMLInputElement) {
          // eslint-disable-next-line no-param-reassign
          option.checked = Boolean(
            option.value === '' && option.type === 'radio',
          );
        }
      });
  }, [navigate, searchFiltersByName?.type, searchParamsRef]);

  return {
    clearAllFilters,
    filtersRef,
    areFiltersActive,
  };
}
