export enum EducationLevel {
  NO_REQUIREMENT = 'NO_REQUIREMENT',
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  TWO_YEAR_DEGREE = 'TWO_YEAR_DEGREE',
  FOUR_YEAR_DEGREE = 'FOUR_YEAR_DEGREE',
  MASTERS_DEGREE = 'MASTERS_DEGREE',
  JD = 'JD',
  PHD = 'PHD',
  MD = 'MD',
  OTHER = 'OTHER',
}

export const EDUCATION_LEVELS = Object.defineProperties(
  {},
  {
    [EducationLevel.NO_REQUIREMENT]: {
      get: () => getText('No Education Requirement'),
      enumerable: true,
    },
    [EducationLevel.HIGH_SCHOOL]: {
      get: () => getText('High School Diploma Required'),
      enumerable: true,
    },
    [EducationLevel.TWO_YEAR_DEGREE]: {
      get: () => getText('2-Year Degree Required'),
      enumerable: true,
    },
    [EducationLevel.FOUR_YEAR_DEGREE]: {
      get: () => getText('4-Year Degree Required'),
      enumerable: true,
    },
    [EducationLevel.MASTERS_DEGREE]: {
      get: () => getText("Master's Degree Required"),
      enumerable: true,
    },
    [EducationLevel.JD]: {
      get: () => getText('J.D. Required'),
      enumerable: true,
    },
    [EducationLevel.PHD]: {
      get: () => getText('Doctorate Required'),
      enumerable: true,
    },
    [EducationLevel.MD]: {
      get: () => getText('M.D. Required'),
      enumerable: true,
    },
    [EducationLevel.OTHER]: {
      get: () => getText('Other Education Requirement'),
      enumerable: true,
    },
  },
) as Record<EducationLevel, string>;

export const EDUCATION_LEVEL_CHECKBOX_OPTIONS = () =>
  Object.entries(EDUCATION_LEVELS).map(([key, value]) => ({
    name: key,
    label: value,
  }));

export const EDUCATION_RADIO_OR_SELECT_OPTIONS = () =>
  Object.entries(EDUCATION_LEVELS).map(([key, value]) => ({
    value: key,
    label: value,
  }));
