import styled from 'styled-components';

import { Button } from 'components/Button/Button';
import { spacing } from 'theme/theme';

import { ActionToastFeedbackAction } from './useActionToast';

const ActionToastFeedbackButtonContent = styled.div<{
  $iconFlipped?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: ${spacing[8]};

  /* Text layout / icon vertical align hack */
  & > :last-child {
    div[role='img'] {
      translate: 0 1px;
    }
  }
`;

export function ActionToastFeedbackButton({
  onFeedback,
  positiveLabel = 'Good',
  negativeLabel = 'Bad',
}: Pick<
  ActionToastFeedbackAction,
  'onFeedback' | 'positiveLabel' | 'negativeLabel'
>) {
  return (
    <ActionToastFeedbackButtonContent>
      <Button
        icon={{ name: 'thumbs-up', type: 'normalized' }}
        onClick={() => onFeedback('positive')}
        type="button"
      >
        {positiveLabel}
      </Button>
      <Button
        icon={{ name: 'thumbs-down', type: 'normalized' }}
        variant="danger"
        onClick={() => onFeedback('negative')}
        type="button"
      >
        {negativeLabel}
      </Button>
    </ActionToastFeedbackButtonContent>
  );
}
