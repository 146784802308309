import { ApiPreferredListingType } from 'api/types/ApiPreferredListingType';
import { ApiOrgId } from 'api/types/ApiTypedId';
import { ApiOrgSize } from 'modules/listing/api/org/types/ApiOrgSize';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import { OrgDashboardApiOrg } from './types/OrgDashboardApiOrg';

type ApiResponse = {
  org: OrgDashboardApiOrg;
  user: {
    preferredListingTypes: ApiPreferredListingType[];
    orgSize: ApiOrgSize;
  };
};

function mapResponse(data: ApiResponse) {
  return data;
}

const endpoint = new FetchEndpoint({
  urlFactory: (orgId: ApiOrgId) => `/data/orgdashboard/${orgId}/organization`,
  mapResponse,
});

// Exports

export const orgDashboardApiFetchOrg = endpoint.createFetchFn();

export const useOrgDashboardApiFetchOrg = endpoint.createUseFetchHook();
