export enum AreaOfFocus {
  AGRICULTURE = 'AGRICULTURE',
  ANIMALS = 'ANIMALS',
  ARTS_MUSIC = 'ARTS_MUSIC',
  CHILDREN_YOUTH = 'CHILDREN_YOUTH',
  CIVIC_ENGAGEMENT = 'CIVIC_ENGAGEMENT',
  CLIMATE_CHANGE = 'CLIMATE_CHANGE',
  COMMUNICATIONS_ACCESS = 'COMMUNICATIONS_ACCESS',
  COMMUNITY_DEVELOPMENT = 'COMMUNITY_DEVELOPMENT',
  CONFLICT_RESOLUTION = 'CONFLICT_RESOLUTION',
  CONSUMER_PROTECTION = 'CONSUMER_PROTECTION',
  CRIME_SAFETY = 'CRIME_SAFETY',
  DISABILITY = 'DISABILITY',
  DISASTER_RELIEF = 'DISASTER_RELIEF',
  ECONOMIC_DEVELOPMENT = 'ECONOMIC_DEVELOPMENT',
  EDUCATION = 'EDUCATION',
  ENERGY = 'ENERGY',
  ENTREPRENEURSHIP = 'ENTREPRENEURSHIP',
  ENVIRONMENT = 'ENVIRONMENT',
  FAMILY = 'FAMILY',
  FINANCIAL_LITERACY_PERSONAL_FINANCE = 'FINANCIAL_LITERACY_PERSONAL_FINANCE',
  HEALTH_MEDICINE = 'HEALTH_MEDICINE',
  HOUSING_HOMELESSNESS = 'HOUSING_HOMELESSNESS',
  HUMAN_RIGHTS_CIVIL_LIBERTIES = 'HUMAN_RIGHTS_CIVIL_LIBERTIES',
  HUNGER_FOOD_SECURITY = 'HUNGER_FOOD_SECURITY',
  IMMIGRANTS_OR_REFUGEES = 'IMMIGRANTS_OR_REFUGEES',
  INTERNATIONAL_RELATIONS = 'INTERNATIONAL_RELATIONS',
  JOB_WORKPLACE = 'JOB_WORKPLACE',
  LEGAL_ASSISTANCE = 'LEGAL_ASSISTANCE',
  LGBTQ = 'LGBTQ',
  MEDIA = 'MEDIA',
  MEN = 'MEN',
  MENTAL_HEALTH = 'MENTAL_HEALTH',
  MICROFINANCE = 'MICROFINANCE',
  PHILANTHROPY = 'PHILANTHROPY',
  POLICY = 'POLICY',
  POVERTY = 'POVERTY',
  PRISON_REFORM = 'PRISON_REFORM',
  RACE_ETHNICITY = 'RACE_ETHNICITY',
  RELIGION_SPIRITUALITY = 'RELIGION_SPIRITUALITY',
  REPRODUCTIVE_HEALTH_RIGHTS = 'REPRODUCTIVE_HEALTH_RIGHTS',
  RESEARCH_SOCIAL_SCIENCE = 'RESEARCH_SOCIAL_SCIENCE',
  RURAL_AREAS = 'RURAL_AREAS',
  SCIENCE_TECHNOLOGY = 'SCIENCE_TECHNOLOGY',
  SENIORS_RETIREMENT = 'SENIORS_RETIREMENT',
  SEXUAL_ABUSE_HUMAN_TRAFFICKING = 'SEXUAL_ABUSE_HUMAN_TRAFFICKING',
  SPORTS_RECREATION = 'SPORTS_RECREATION',
  SUBSTANCE_ABUSE_ADDICTION = 'SUBSTANCE_ABUSE_ADDICTION',
  TRAVEL_HOSPITALITY = 'TRAVEL_HOSPITALITY',
  TRANSPARENCY_OVERSIGHT = 'TRANSPARENCY_OVERSIGHT',
  TRANSPORTATION = 'TRANSPORTATION',
  URBAN_AREAS = 'URBAN_AREAS',
  VETERANS = 'VETERANS',
  VICTIM_SUPPORT = 'VICTIM_SUPPORT',
  VOLUNTEERING = 'VOLUNTEERING',
  WATER_SANITATION = 'WATER_SANITATION',
  WOMEN = 'WOMEN',
}

export const AREAS_OF_FOCUS = Object.defineProperties(
  {},
  {
    [AreaOfFocus.AGRICULTURE]: {
      get: () => getText('Agriculture'),
      enumerable: true,
    },
    [AreaOfFocus.ANIMALS]: { get: () => getText('Animals'), enumerable: true },
    [AreaOfFocus.ARTS_MUSIC]: {
      get: () => getText('Arts & Music'),
      enumerable: true,
    },
    [AreaOfFocus.CHILDREN_YOUTH]: {
      get: () => getText('Children & Youth'),
      enumerable: true,
    },
    [AreaOfFocus.CIVIC_ENGAGEMENT]: {
      get: () => getText('Civic Engagement'),
      enumerable: true,
    },
    [AreaOfFocus.CLIMATE_CHANGE]: {
      get: () => getText('Climate Change'),
      enumerable: true,
    },
    [AreaOfFocus.COMMUNICATIONS_ACCESS]: {
      get: () => getText('Communications Access'),
      enumerable: true,
    },
    [AreaOfFocus.COMMUNITY_DEVELOPMENT]: {
      get: () => getText('Community Development'),
      enumerable: true,
    },
    [AreaOfFocus.CONFLICT_RESOLUTION]: {
      get: () => getText('Conflict Resolution'),
      enumerable: true,
    },
    [AreaOfFocus.CONSUMER_PROTECTION]: {
      get: () => getText('Consumer Protection'),
      enumerable: true,
    },
    [AreaOfFocus.CRIME_SAFETY]: {
      get: () => getText('Crime & Safety'),
      enumerable: true,
    },
    [AreaOfFocus.DISABILITY]: {
      get: () => getText('Disability'),
      enumerable: true,
    },
    [AreaOfFocus.DISASTER_RELIEF]: {
      get: () => getText('Disaster Relief'),
      enumerable: true,
    },
    [AreaOfFocus.ECONOMIC_DEVELOPMENT]: {
      get: () => getText('Economic Development'),
      enumerable: true,
    },
    [AreaOfFocus.EDUCATION]: {
      get: () => getText('Education'),
      enumerable: true,
    },
    [AreaOfFocus.ENERGY]: { get: () => getText('Energy'), enumerable: true },
    [AreaOfFocus.ENTREPRENEURSHIP]: {
      get: () => getText('Entrepreneurship'),
      enumerable: true,
    },
    [AreaOfFocus.ENVIRONMENT]: {
      get: () => getText('Environment & Sustainability'),
      enumerable: true,
    },
    [AreaOfFocus.FAMILY]: { get: () => getText('Family'), enumerable: true },
    [AreaOfFocus.FINANCIAL_LITERACY_PERSONAL_FINANCE]: {
      get: () => getText('Financial Literacy & Personal Finance'),
      enumerable: true,
    },
    [AreaOfFocus.HEALTH_MEDICINE]: {
      get: () => getText('Health & Medicine'),
      enumerable: true,
    },
    [AreaOfFocus.HOUSING_HOMELESSNESS]: {
      get: () => getText('Housing & Homelessness'),
      enumerable: true,
    },
    [AreaOfFocus.HUMAN_RIGHTS_CIVIL_LIBERTIES]: {
      get: () => getText('Human Rights & Civil Liberties'),
      enumerable: true,
    },
    [AreaOfFocus.HUNGER_FOOD_SECURITY]: {
      get: () => getText('Hunger, Food Security'),
      enumerable: true,
    },
    [AreaOfFocus.IMMIGRANTS_OR_REFUGEES]: {
      get: () => getText('Immigrants or Refugees'),
      enumerable: true,
    },
    [AreaOfFocus.INTERNATIONAL_RELATIONS]: {
      get: () => getText('International Relations'),
      enumerable: true,
    },
    [AreaOfFocus.JOB_WORKPLACE]: {
      get: () => getText('Job & Workplace'),
      enumerable: true,
    },
    [AreaOfFocus.LEGAL_ASSISTANCE]: {
      get: () => getText('Legal Assistance'),
      enumerable: true,
    },
    [AreaOfFocus.LGBTQ]: { get: () => getText('LGBTQ'), enumerable: true },
    [AreaOfFocus.MEDIA]: { get: () => getText('Media'), enumerable: true },
    [AreaOfFocus.MEN]: { get: () => getText('Men'), enumerable: true },
    [AreaOfFocus.MENTAL_HEALTH]: {
      get: () => getText('Mental Health'),
      enumerable: true,
    },
    [AreaOfFocus.MICROFINANCE]: {
      get: () => getText('Microfinance'),
      enumerable: true,
    },
    [AreaOfFocus.PHILANTHROPY]: {
      get: () => getText('Philanthropy'),
      enumerable: true,
    },
    [AreaOfFocus.POLICY]: { get: () => getText('Policy'), enumerable: true },
    [AreaOfFocus.POVERTY]: { get: () => getText('Poverty'), enumerable: true },
    [AreaOfFocus.PRISON_REFORM]: {
      get: () => getText('Prison Reform'),
      enumerable: true,
    },
    [AreaOfFocus.RACE_ETHNICITY]: {
      get: () => getText('Race & Ethnicity'),
      enumerable: true,
    },
    [AreaOfFocus.RELIGION_SPIRITUALITY]: {
      get: () => getText('Religion & Spirituality'),
      enumerable: true,
    },
    [AreaOfFocus.REPRODUCTIVE_HEALTH_RIGHTS]: {
      get: () => getText('Reproductive Health/Rights'),
      enumerable: true,
    },
    [AreaOfFocus.RESEARCH_SOCIAL_SCIENCE]: {
      get: () => getText('Research & Social Science'),
      enumerable: true,
    },
    [AreaOfFocus.RURAL_AREAS]: {
      get: () => getText('Rural Areas'),
      enumerable: true,
    },
    [AreaOfFocus.SCIENCE_TECHNOLOGY]: {
      get: () => getText('Science & Technology'),
      enumerable: true,
    },
    [AreaOfFocus.SENIORS_RETIREMENT]: {
      get: () => getText('Seniors & Retirement'),
      enumerable: true,
    },
    [AreaOfFocus.SEXUAL_ABUSE_HUMAN_TRAFFICKING]: {
      get: () => getText('Sexual Abuse & Human Trafficking'),
      enumerable: true,
    },
    [AreaOfFocus.SPORTS_RECREATION]: {
      get: () => getText('Sports & Recreation'),
      enumerable: true,
    },
    [AreaOfFocus.SUBSTANCE_ABUSE_ADDICTION]: {
      get: () => getText('Substance Use & Addiction'),
      enumerable: true,
    },
    [AreaOfFocus.TRAVEL_HOSPITALITY]: {
      get: () => getText('Travel & Hospitality'),
      enumerable: true,
    },
    [AreaOfFocus.TRANSPARENCY_OVERSIGHT]: {
      get: () => getText('Transparency & Oversight'),
      enumerable: true,
    },
    [AreaOfFocus.TRANSPORTATION]: {
      get: () => getText('Transportation'),
      enumerable: true,
    },
    [AreaOfFocus.URBAN_AREAS]: {
      get: () => getText('Urban Areas'),
      enumerable: true,
    },
    [AreaOfFocus.VETERANS]: {
      get: () => getText('Veterans'),
      enumerable: true,
    },
    [AreaOfFocus.VICTIM_SUPPORT]: {
      get: () => getText('Victim Support'),
      enumerable: true,
    },
    [AreaOfFocus.VOLUNTEERING]: {
      get: () => getText('Volunteering'),
      enumerable: true,
    },
    [AreaOfFocus.WATER_SANITATION]: {
      get: () => getText('Water & Sanitation'),
      enumerable: true,
    },
    [AreaOfFocus.WOMEN]: { get: () => getText('Women'), enumerable: true },
  },
) as Record<AreaOfFocus, string>;

export const AREAS_OF_FOCUS_RADIO_OR_SELECT_OPTIONS = () =>
  Object.entries(AREAS_OF_FOCUS).map(([key, value]) => ({
    value: key as keyof typeof AREAS_OF_FOCUS,
    label: value,
  }));

export const AREAS_OF_FOCUS_CHECKBOX_OPTIONS = () =>
  Object.entries(AREAS_OF_FOCUS).map(([key, value]) => ({
    name: key as keyof typeof AREAS_OF_FOCUS,
    label: value,
  }));
