import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { ACTION_TYPE_PLURAL } from 'utils/constants/general/actionTypePlural';

export const actionTypeFacet = {
  id: 'actionTypeFacet',
  name: 'actionType',
  get title() {
    return getText('Opportunity Type');
  },
  type: 'array',
  options: Object.keys(ACTION_TYPE_PLURAL).filter(
    (actionType) => actionType !== 'LOAN',
  ),
  optionTitles: ACTION_TYPE_PLURAL,
  facetToResetOnChange: 'volopSourceFacet',
} as const satisfies SearchFacetData;
