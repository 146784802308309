export enum VolopFunction {
  ACCOUNTING_FINANCE = 'ACCOUNTING_FINANCE',
  ADMINISTRATIVE_OFFICE_SUPPORT = 'ADMINISTRATIVE_OFFICE_SUPPORT',
  ADVOCACY = 'ADVOCACY',
  ANIMAL_CARE = 'ANIMAL_CARE',
  ARCHIVING_DATA_ENTRY = 'ARCHIVING_DATA_ENTRY',
  ARTS_CRAFTS = 'ARTS_CRAFTS',
  BOARD_COMMITTEE = 'BOARD_COMMITTEE',
  CITIZENSHIP = 'CITIZENSHIP',
  COMMUNICATIONS_MARKETING = 'COMMUNICATIONS_MARKETING',
  COMMUNITY_BELONGING = 'COMMUNITY_BELONGING',
  CORPORATE_PHILANTHROPY = 'CORPORATE_PHILANTHROPY',
  CORPORATE_RESPONSIBILITY = 'CORPORATE_RESPONSIBILITY',
  DATA_ANALYSIS = 'DATA_ANALYSIS',
  DESIGN = 'DESIGN',
  DISASTER_RELIEF = 'DISASTER_RELIEF',
  DIVERSITY_EQUITY_INCLUSION = 'DIVERSITY_EQUITY_INCLUSION',
  DONATION_DRIVE = 'DONATION_DRIVE',
  ELDER_CARE = 'ELDER_CARE',
  EMPLOYEE_ENGAGEMENT = 'EMPLOYEE_ENGAGEMENT',
  EVENT_SUPPORT = 'EVENT_SUPPORT',
  FOOD_SERVICE = 'FOOD_SERVICE',
  FUNDRAISING = 'FUNDRAISING',
  HEALTH_WELLNESS = 'HEALTH_WELLNESS',
  LANGUAGES = 'LANGUAGES',
  LEGAL = 'LEGAL',
  MENTOR_TUTOR = 'MENTOR_TUTOR',
  MUSIC = 'MUSIC',
  PARTNERSHIPS = 'PARTNERSHIPS',
  PHOTOGRAPHY_VIDEOGRAPHY = 'PHOTOGRAPHY_VIDEOGRAPHY',
  PHYSICAL_SERVICE = 'PHYSICAL_SERVICE',
  PROJECT_RESEARCH_STUDY = 'PROJECT_RESEARCH_STUDY',
  PUBLIC_SPEAKING = 'PUBLIC_SPEAKING',
  SPORTS_FITNESS_COACHING = 'SPORTS_FITNESS_COACHING',
  SUSTAINABILITY = 'SUSTAINABILITY',
  TECHNOLOGY_SUPPORT_WEB_DESIGN = 'TECHNOLOGY_SUPPORT_WEB_DESIGN',
}

export const VOLOP_FUNCTIONS = Object.defineProperties(
  {},
  {
    [VolopFunction.ACCOUNTING_FINANCE]: {
      get: () => getText('Accounting & Finance'),
      enumerable: true,
    },
    [VolopFunction.ADMINISTRATIVE_OFFICE_SUPPORT]: {
      get: () => getText('Administrative/Office Support'),
      enumerable: true,
    },
    [VolopFunction.ADVOCACY]: {
      get: () => getText('Advocacy'),
      enumerable: true,
    },
    [VolopFunction.ANIMAL_CARE]: {
      get: () => getText('Animal Care'),
      enumerable: true,
    },
    [VolopFunction.ARCHIVING_DATA_ENTRY]: {
      get: () => getText('Archiving/Data Entry'),
      enumerable: true,
    },
    [VolopFunction.ARTS_CRAFTS]: {
      get: () => getText('Arts & Crafts'),
      enumerable: true,
    },
    [VolopFunction.BOARD_COMMITTEE]: {
      get: () => getText('Board/Committee'),
      enumerable: true,
    },
    [VolopFunction.CITIZENSHIP]: {
      get: () => getText('Citizenship'),
      enumerable: true,
    },
    [VolopFunction.COMMUNICATIONS_MARKETING]: {
      get: () => getText('Communications & Marketing'),
      enumerable: true,
    },
    [VolopFunction.COMMUNITY_BELONGING]: {
      get: () => getText('Community Belonging'),
      enumerable: true,
    },
    [VolopFunction.CORPORATE_PHILANTHROPY]: {
      get: () => getText('Corporate Philanthropy'),
      enumerable: true,
    },
    [VolopFunction.CORPORATE_RESPONSIBILITY]: {
      get: () => getText('Corporate Responsibility'),
      enumerable: true,
    },
    [VolopFunction.DATA_ANALYSIS]: {
      get: () => getText('Data Analysis'),
      enumerable: true,
    },
    [VolopFunction.DESIGN]: { get: () => getText('Design'), enumerable: true },
    [VolopFunction.DISASTER_RELIEF]: {
      get: () => getText('Disaster Relief'),
      enumerable: true,
    },
    [VolopFunction.DIVERSITY_EQUITY_INCLUSION]: {
      get: () => getText('Diversity, Equity & Inclusion'),
      enumerable: true,
    },
    [VolopFunction.DONATION_DRIVE]: {
      get: () => getText('Donation Drive'),
      enumerable: true,
    },
    [VolopFunction.ELDER_CARE]: {
      get: () => getText('Elder Care'),
      enumerable: true,
    },
    [VolopFunction.EMPLOYEE_ENGAGEMENT]: {
      get: () => getText('Employee Engagement'),
      enumerable: true,
    },
    [VolopFunction.EVENT_SUPPORT]: {
      get: () => getText('Event Support'),
      enumerable: true,
    },
    [VolopFunction.FOOD_SERVICE]: {
      get: () => getText('Food Service'),
      enumerable: true,
    },
    [VolopFunction.FUNDRAISING]: {
      get: () => getText('Fundraising'),
      enumerable: true,
    },
    [VolopFunction.HEALTH_WELLNESS]: {
      get: () => getText('Health & Wellness'),
      enumerable: true,
    },
    [VolopFunction.LANGUAGES]: {
      get: () => getText('Languages'),
      enumerable: true,
    },
    [VolopFunction.LEGAL]: { get: () => getText('Legal'), enumerable: true },
    [VolopFunction.MENTOR_TUTOR]: {
      get: () => getText('Mentor/Tutor'),
      enumerable: true,
    },
    [VolopFunction.MUSIC]: { get: () => getText('Music'), enumerable: true },
    [VolopFunction.PARTNERSHIPS]: {
      get: () => getText('Partnerships'),
      enumerable: true,
    },
    [VolopFunction.PHOTOGRAPHY_VIDEOGRAPHY]: {
      get: () => getText('Photography/Videography'),
      enumerable: true,
    },
    [VolopFunction.PHYSICAL_SERVICE]: {
      get: () => getText('Physical Service (Painting, Farming, etc.)'),
      enumerable: true,
    },
    [VolopFunction.PROJECT_RESEARCH_STUDY]: {
      get: () => getText('Project or Research Study'),
      enumerable: true,
    },
    [VolopFunction.PUBLIC_SPEAKING]: {
      get: () => getText('Public Speaking'),
      enumerable: true,
    },
    [VolopFunction.SPORTS_FITNESS_COACHING]: {
      get: () => getText('Sports & Fitness/Coaching'),
      enumerable: true,
    },
    [VolopFunction.SUSTAINABILITY]: {
      get: () => getText('Sustainability'),
      enumerable: true,
    },
    [VolopFunction.TECHNOLOGY_SUPPORT_WEB_DESIGN]: {
      get: () => getText('Technology Support & Web Design'),
      enumerable: true,
    },
  },
) as Record<VolopFunction, string>;

export const VOLOP_FUNCTION_CHECKBOX_OPTIONS = () =>
  Object.entries(VOLOP_FUNCTIONS).map(([key, value]) => ({
    name: key,
    label: value,
  }));

export const VOLOP_FUNCTION_RADIO_OR_SELECT_OPTIONS = () =>
  Object.entries(VOLOP_FUNCTIONS).map(([key, value]) => ({
    value: key,
    label: value,
  }));
