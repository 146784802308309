import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router';

import { useLatest } from 'hooks/useLatest';
import { SearchContextHeaderLocationState } from 'modules/search/containers/SearchContextHeader/SearchContextHeaderLocationState';
import { getParsedSearchQuery } from 'modules/search/helpers/getParsedSearchQuery';
import { useSearchPageActions } from 'modules/search/hooks/useSearchPageActions';
import { SearchPageLocationState } from 'modules/search/pages/Search/types/SearchPageLocationState';
import { searchGetRoutePathSwitch } from 'modules/search/routing/helpers/searchGetRoutePathSwitch';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { SearchType } from 'modules/search/types/SearchType';
import {
  getSearchLocation,
  updateSearchLocation,
  useSearchLocation,
  useSearchQuery,
} from 'modules/search/zustand-stores/searchStore';
import { EMPTY_SEARCH_LOCATION } from 'utils/constants/general/emptySearchLocation';
import { truncateToUTF8Bytes } from 'utils/string/truncateToUTF8Bytes';
import { deferAndStartRouteLoadingAnimation } from 'utils/ui/routeLoadingAnimation';

type Args = {
  defaultSearchType: SearchType;
  isInSearchPage: boolean;
  isInSearchLandingPage: boolean;
};
export function usePageHeaderContainerSearch({
  defaultSearchType,
  isInSearchPage,
  isInSearchLandingPage,
}: Args) {
  const location = useLocation<SearchContextHeaderLocationState>();
  const navigate = useNavigate();

  const { setSearchUrlQueryOrLocationChanged } = useSearchPageActions({
    isInSearchLandingPage,
  });

  const [searchParams] = useSearchParams();
  const searchParamsRef = useLatest(searchParams);

  const reduxQuery = useSearchQuery();
  const initialQuery = useMemo(() => {
    if (
      (reduxQuery && location.state?.showSearchContext) ||
      isInSearchLandingPage
    ) {
      return reduxQuery;
    }

    return truncateToUTF8Bytes(searchParams.get('q') || '', 512);
  }, [
    isInSearchLandingPage,
    location.state?.showSearchContext,
    reduxQuery,
    searchParams,
  ]);

  const searchLocation = useSearchLocation();

  const changeSearchLocation = useCallback(
    (newSearchLocation: SearchLocation | undefined): boolean => {
      if (newSearchLocation) {
        // The LocationInput triggers the change event twice except for the `Remote` option
        if (
          newSearchLocation.text &&
          getSearchLocation()?.text === newSearchLocation.text
        )
          return false;

        deferAndStartRouteLoadingAnimation(() => {
          updateSearchLocation(newSearchLocation, true);
        });
      } else {
        deferAndStartRouteLoadingAnimation(() => {
          updateSearchLocation(EMPTY_SEARCH_LOCATION, true);
        });
      }

      return true;
    },
    [],
  );

  const search = useCallback(
    (data: {
      listingType: SearchType;
      query: string;
      triggeredByRemoteLocationChange: boolean;
    }) => {
      if (isInSearchPage && data.listingType === defaultSearchType) {
        setSearchUrlQueryOrLocationChanged(
          {
            query: data.query,
            triggeredByRemoteLocationChange:
              data.triggeredByRemoteLocationChange,
          },
          { searchFrom: 'Header Search Bar' },
        );
      } else {
        const { parsedQuery, locationType, remoteKeywords } =
          getParsedSearchQuery({ query: data.query });

        navigate(
          searchGetRoutePathSwitch({
            lang: CURRENT_LOCALE(),
            filtersByName: {
              type: data.listingType,
              locationType: data.triggeredByRemoteLocationChange
                ? ['REMOTE']
                : locationType,
            },
            q: parsedQuery,
            // We wipe everything but the radius when a location is set
            radius: getSearchLocation()?.text
              ? searchParamsRef.current.get('radius') || undefined
              : undefined,
          }),
          {
            state: {
              searchFrom: 'Header Search Bar',
              remoteKeywords,
            } satisfies SearchPageLocationState,
          },
        );
      }
    },
    [
      defaultSearchType,
      isInSearchPage,
      navigate,
      searchParamsRef,
      setSearchUrlQueryOrLocationChanged,
    ],
  );

  return {
    initialSearchType: defaultSearchType,
    initialQuery,
    searchLocation,
    changeSearchLocation,
    search,
    searchOnClear: isInSearchPage,
  };
}
