import { ApiISODateString } from 'api/types/ApiTypedDate';
import { SearchFacetData } from 'modules/search/types/SearchFacetData';

export const actionDateFacet = {
  id: 'actionDateFacet',
  name: 'actionDate',
  names: ['endsGT', 'startsLT'],
  get title() {
    return getText('Date');
  },
  type: 'date',
  // Do not apply to date filters
  options: [],
  optionTitles: {},
} as const satisfies SearchFacetData;

export type ActionDateFacetValue = {
  endsGT: ApiISODateString | undefined;
  startsLT: ApiISODateString | undefined;
};
