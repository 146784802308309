import { TextBadge } from 'components/Badge/TextBadge';
import { SearchFilterAccordionItem } from 'modules/search/components/Filters/AccordionItem/SearchFilterAccordionItem';
import { SearchFilterDropdown } from 'modules/search/components/Filters/Dropdown/SearchFilterDropdown';
import { SearchFilterCheckboxFacetOptions } from 'modules/search/components/Filters/FacetOptions/SearchFilterCheckboxFacetOptions';
import { useSearchFacetSelectedValues } from 'modules/search/hooks/filters/useSearchFacetSelectedValues';
import { SearchFilterVariant } from 'modules/search/types/SearchFilterVariant';
import { PROFESSIONAL_LEVEL_CHECKBOX_OPTIONS } from 'utils/constants/general/professionalLevels';

type Props = {
  variant?: SearchFilterVariant;
};
export function ProfessionalLevelFilter({ variant = 'dropdown' }: Props) {
  const selectedFilterValues =
    // @ts-expect-error Fix Type
    useSearchFacetSelectedValues('professionalLevel');

  const FilterVariant =
    variant === 'accordion' ? SearchFilterAccordionItem : SearchFilterDropdown;

  return (
    <FilterVariant
      facetType="professionalLevel"
      icon="stairs"
      label={
        <>
          {getText('Experience Level')}{' '}
          <TextBadge content={selectedFilterValues.length} />
        </>
      }
    >
      <SearchFilterCheckboxFacetOptions
        facetType="professionalLevel"
        initialValue={selectedFilterValues}
        options={PROFESSIONAL_LEVEL_CHECKBOX_OPTIONS()}
      />
    </FilterVariant>
  );
}
