import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { AREAS_OF_FOCUS } from 'utils/constants/general/areasOfFocus';

export const volopAreasOfFocusFacet = {
  id: 'volopAreasOfFocusFacet',
  name: 'areasOfFocus',
  get title() {
    return getText('Issue Areas');
  },
  type: 'array',
  options: Object.keys(AREAS_OF_FOCUS),
  optionTitles: AREAS_OF_FOCUS,
} as const satisfies SearchFacetData;
