import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { JOB_FUNCTIONS } from 'utils/constants/general/jobFunctions';

export const jobKeywordsFacet = {
  id: 'jobKeywordsFacet',
  name: 'functions',
  get title() {
    return getText('Job Function');
  },
  type: 'array',
  options: Object.keys(JOB_FUNCTIONS),
  optionTitles: JOB_FUNCTIONS,
} as const satisfies SearchFacetData;
