import { LocationType } from './locationType';

export const EVENT_LOCATION_TYPE = Object.defineProperties(
  {},
  {
    // TRANSLATORS: listing location type
    [LocationType.ONSITE]: {
      get: () => getText('In Person'),
      enumerable: true,
    },
    // TRANSLATORS: listing location type
    [LocationType.HYBRID]: { get: () => getText('Hybrid'), enumerable: true },
    // TRANSLATORS: listing location type
    [LocationType.REMOTE]: { get: () => getText('Virtual'), enumerable: true },
  },
) as Record<LocationType, string>;

export const EVENT_LOCATION_TYPE_CHECKBOX_OPTIONS = () =>
  Object.entries(EVENT_LOCATION_TYPE).map(([key, value]) => ({
    name: key,
    label: value,
  }));
