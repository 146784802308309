export enum EmploymentType {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
  TEMPORARY = 'TEMPORARY',
  CONTRACT = 'CONTRACT',
}

export const EMPLOYMENT_TYPES = Object.defineProperties(
  {},
  {
    [EmploymentType.FULL_TIME]: {
      get: () => getText('Full-Time Employee'),
      enumerable: true,
    },
    [EmploymentType.PART_TIME]: {
      get: () => getText('Part-Time Employee'),
      enumerable: true,
    },
    [EmploymentType.TEMPORARY]: {
      get: () => getText('Temporary'),
      enumerable: true,
    },
    [EmploymentType.CONTRACT]: {
      get: () => getText('Contract/Freelance'),
      enumerable: true,
    },
  },
) as Record<EmploymentType, string>;

export const EMPLOYMENT_TYPE_CHECKBOX_OPTIONS = () =>
  Object.entries(EMPLOYMENT_TYPES).map(([key, value]) => ({
    name: key,
    label: value,
  }));
