import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { VOLOP_FUNCTIONS } from 'utils/constants/general/volopFunctions';

export const volopKeywordsFacet = {
  id: 'volopKeywordsFacet',
  name: 'functions',
  get title() {
    return getText('Skills');
  },
  type: 'array',
  options: Object.keys(VOLOP_FUNCTIONS),
  optionTitles: VOLOP_FUNCTIONS,
} as const satisfies SearchFacetData;
